import React from "react";
import { InputAdornment, IconButton, MenuItem, TextField, Select, FormControl, FormHelperText, InputLabel, Chip } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function FormTextField({
    name,
    label,
    select = false,
    selectOptions = [],
    selectFixedOptions = [],
    multiSelect = false,
    multiline = false,
    minRows = 1,
    maxRows = 1,
    fullWidth = true,
    minLength = null,
    maxLength = null,
    object = {},
    type = "text",
    password = false,
    placeholder = "",
    handleChange,
    hasPermission = false,
    required = false,
    error: externalError,
    helperText: externalHelperText,
    clearable = false,
    disabled = false,
    sx = {},
}) {
    // Interner State für Passwort-Sichtbarkeit
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    // Interner Check auf Länge
    const isLengthError = () => {
        if (object[name]) {
            const current = object[name].length;        
            if (minLength && minLength > current) {
                return true;
            } else if (maxLength && maxLength < current) {
                return true;
            }
        }
        return false;
    };

    // Interner Hilfetext, falls maxLength überschritten
    const getLengthHelperText = () => {
        if (object[name]) {
            const current = object[name].length;        
            if (minLength && minLength > current) {
                return `${current}/${minLength} Zeichen`;
            } else if (maxLength && maxLength < current) {
                return `${current}/${maxLength} Zeichen`;
            }
        }
        return "";
    };

    // Gesamter Error-Zustand (extern oder intern)
    const combinedError = () => {
        // Externer Fehler (z.B. required) oder interner (z.B. maxLength) oder customError
        return Boolean(externalError || isLengthError());
    };

    // Gesamter HelperText, zusammengefügt aus externem und internem Text
    const combinedHelperText = () => {
        // Falls die Länge überschritten ist, diesen Text nehmen, sonst den customHelperText
        const localText = isLengthError() ? getLengthHelperText() : externalHelperText || "";

        // Kein externer Fehler -> nur den lokalen Text
        return localText;
    };

    const getValue = () => {
        const val = object[name];

        // Falls null/undefined -> leerer String
        if (val == null) {
            return multiSelect ? [] : "";
        }

        if (multiSelect) {
            return Array.isArray(val) ? val : val ? [val] : [];
        }

        // Falls val ein Objekt (oder Array) ist -> JSON-String generieren
        if (typeof val === "object") {
            return JSON.stringify(val, null, 2);
        }

        // Falls val ein Blob-String ist -> Meldung anzeigen
        if (typeof val === "string" && val.includes("blob:")) {
            return "Lokale Datei";
        }

        // Sonst einfach als String zurückgeben
        return String(val);
    };

    if (multiSelect) {
        return (
            <FormControl fullWidth={fullWidth} variant="standard" size="small" error={combinedHelperText()}>
                <InputLabel shrink>{label}</InputLabel>
                <Select
                    name={name}
                    multiple={multiSelect}
                    value={getValue()}
                    onChange={handleChange}
                    inputProps={{ readOnly: !hasPermission }}
                    displayEmpty
                    disabled={disabled}
                    renderValue={(selected) =>
                        multiSelect ? (
                            <div style={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
                                {selected.map((value) => (
                                    <Chip
                                        key={value}
                                        label={selectOptions.find((o) => o.value === value)?.label || value}
                                        sx={{
                                            margin: 0,
                                            textTransform: "none",
                                            fontWeight: 500
                                        }}
                                        size="small"
                                    />
                                ))}
                            </div>
                        ) : (
                            selectOptions.find((o) => o.value === selected)?.label || selected
                        )
                    }
                >
                    {selectOptions.map((option) => {
                        const isFixed = selectFixedOptions.some((o) => o.value === option.value);

                        return (
                            <MenuItem key={option.value} value={option.value} disabled={isFixed}>
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Select>
                {combinedError() && <FormHelperText>{combinedHelperText()}</FormHelperText>}
            </FormControl>
        );
    }

    return (
        <TextField
            name={name}
            label={label}
            value={getValue()}
            onChange={handleChange}
            placeholder={placeholder}
            size="small"
            variant="standard"
            multiline={multiline}
            minRows={minRows}
            maxRows={maxRows}
            fullWidth={fullWidth}
            disabled={disabled}
            sx={sx}
            slotProps={{
                input: {
                    readOnly: !hasPermission,
                    endAdornment: clearable && getValue() !== "" ? (
                        <InputAdornment position="end">
                            <IconButton size="small" onClick={() => handleChange({ target: { name: name, value: "" } })} sx={{
                                marginRight: 3, marginBottom: .5
                            }}>
                                <ClearIcon sx={{
                                    width: 15, height: 15
                                }} />
                            </IconButton>
                        </InputAdornment>
                    ) : password ? (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={
                                    showPassword ? 'hide the password' : 'display the password'
                                }
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                onMouseUp={handleMouseUpPassword}
                                edge="end"
                                size="small"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ) : null
                },
                inputLabel: {
                    shrink: true
                },
            }}
            type={password && showPassword ? 'text' : password && !showPassword ? 'password' : type}
            required={required}
            error={combinedError()}
            helperText={combinedHelperText()}
            select={select}
        >
            {select &&
                selectOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))
            }
        </TextField>
    );
}