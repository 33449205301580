import * as React from "react";

import "moment-timezone";
import moment from "moment";

import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import MenuItem from '@mui/material/MenuItem';
import Moment from "react-moment";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CameraStatusChip from "../../../components/CameraStatusChip";
import CameraSlaChip from "../../../components/CameraSlaChip";
import SidebarAccordion from "../../../components/SidebarAccordion";
import VideoJS from "../../../components/VideoJS";

import CameraStream from "./CameraStream";
import CameraStreaming from "./CameraStreaming";
import CameraRecordingForm from "./CameraRecordingForm";
import CameraAlert from "./CameraAlert";
import CameraSidebarMenu from "./CameraSidebarMenu";

import CameraIdForm from "./CameraIdForm";
import CameraMetadataForm from "./CameraMetadataForm";
import CameraMetadataSeoForm from "./CameraMetadataSeoForm";
import CameraMetadataShareForm from "./CameraMetadataShareForm";
import CameraMetadataLocationForm from "./CameraMetadataLocationForm";

import PageSidebarHeader from "../../../components/PageSidebarHeader";
import FormBox from "../../../components/FormBox";
import { useApp } from "../../../context/App";

function CameraSidebar({ 
    drawerWidth = 450,
    camera = {},
    updateCamera = () => {},
    playerConfig = {},
    openSidebar = false,
    handleSidebarToggle = () => {},
    hasPermission = false,
}) {
    const { client } = useApp();
    const [ingest, setIngest] = React.useState(null);
    const loading = !ingest;
    React.useEffect(() => {
        if (!loading && hasPermission) {
            return undefined;
        }
        (async () => {
            if (!ingest && hasPermission) {
                try {
                    const data = await client.GetCustomerCameraIdIngest({
                        camera_id: camera.id,
                    });
                    setIngest(data);
                } catch (error) {
                    console.log(error.response.data);
                }
            }
        })();
        return () => {};
        // eslint-disable-next-line
    }, [ingest]);

    const [profile, setProfile] = React.useState("main");
    function CameraProfileSection({ 
        streams, 
        setProfile, 
        profile,
    }) {
        const handleChange = (event) => {
            setProfile(event.target.value);
            setVideoJsAutoPlay(true);
        };
        streams.sort((a, b) => a.resolution.split('x')[1] - b.resolution.split('x')[1]);
        return (
            <div style={{position: 'absolute', height: '20px', right: 15, top: 10, zIndex: 1000}}>
                <TextField
                    select
                    size="small"
                    id="player-stream-select"
                    value={profile}
                    defaultValue={'main'}
                    onChange={handleChange}
                    color={"secondary"}
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-root': {
                            backgroundColor: "rgba(0,0,0,.5)",
                            color: "white",
                            padding: "0px 0px",
                            fontSize: "14px",
                            height: "30px",
                        },
                        '& .MuiSvgIcon-root': {
                            color: "white",
                        },
                        '& MuiSelect-select': {
                            padding: "0px 0px",
                        },
                    }}
                >
                    <MenuItem value={'main'}>
                        Adaptive Bitrate (Web)
                    </MenuItem>
                    {camera.streams.map((stream, index) => {
                        if (stream.profile === 'tv') {
                            return (
                                <MenuItem key={index} value="tv">
                                    {stream.resolution} (TV)
                                </MenuItem>
                            );
                        } else {
                            return (
                                <MenuItem key={index} value={String(index)}>
                                    {stream.resolution} (Web)
                                </MenuItem>
                            );
                        }
                    })}
                </TextField>
            </div>
        )
    }

    // VIDEOJS
    const [videoJsOptions, setVideoJsOptions] = React.useState(null);
    const [videoJsAutoPlay, setVideoJsAutoPlay] = React.useState(false);
    React.useEffect(() => {
        if (openSidebar) {
            const options = {
                autoplay: videoJsAutoPlay,
                controls: true,
                responsive: true,
                fluid: true,
                poster: playerConfig.url + camera.id + "/live/main_240.jpg",
                playsinline: true,
                muted: true,
                sources: [
                    {
                        src: playerConfig.url + camera.id + `/live/${profile}.m3u8?token=` + playerConfig.token,
                        type: "application/vnd.apple.mpegurl",
                    },
                ],
            };
            if (videoJsOptions !== options) {
                setVideoJsOptions(options);
                setVideoJsAutoPlay(true);
            };
        } else {
            setVideoJsOptions(null);
            setVideoJsAutoPlay(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, camera, playerConfig, openSidebar]);
    
    return (
        <Stack
            direction="column"
            spacing={0}
            sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
                paddingBottom: 4,
            }}
        >
            <PageSidebarHeader
                title={camera.meta.name}
                share={true}
                handleToggle={handleSidebarToggle}
                submenu={
                    <CameraSidebarMenu
                        camera={camera}
                        updateCamera={updateCamera}
                        hasEditPermission={hasPermission}
                    />
                }
                chips={
                    <>
                        <CameraStatusChip camera={camera} />
                        <CameraSlaChip camera={camera} />
                    </>
                }
                player={
                    <>
                        {camera.streams && profile &&(
                            <CameraProfileSection streams={camera.streams} setProfile={setProfile} profile={profile} />
                        )}
                        {videoJsOptions && <VideoJS key={camera.id} {...videoJsOptions} />}
                    </>
                }
            />
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    width: "100%",
                    paddingBottom: 2,
                    paddingLeft: 2.5,
                    paddingRight: 2.5,
                }}
            >
                <FormBox>
                    <CameraIdForm 
                        camera={camera} 
                        updateCamera={updateCamera}
                        hasPermission={hasPermission}
                    />
                </FormBox>
            </Stack>
            <Divider sx={{marginTop: 0, marginBottom: 0}}/>
            <SidebarAccordion
                first
                title="Streammonitor"
                content={
                    <CameraStream camera={camera} />
                }
            />
            <SidebarAccordion
                title="Störungen"
                content={
                    <CameraAlert 
                        camera={camera} 
                        updateCamera={updateCamera}
                        hasPermission={hasPermission}
                    />
                }
            />
            <SidebarAccordion
                title="Metadaten"
                content={
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: "100%",
                        }}
                    >
                        <FormBox>
                            <CameraMetadataForm
                                camera={camera} 
                                updateCamera={updateCamera}
                                hasPermission={hasPermission}
                            />
                        </FormBox>
                        <FormBox title={"Standort"}>
                            <CameraMetadataLocationForm
                                camera={camera} 
                                updateCamera={updateCamera}
                                hasPermission={hasPermission}
                            />
                        </FormBox>
                        <FormBox title={"Suchmaschinen Beschreibung"}>
                            <CameraMetadataSeoForm
                                camera={camera}
                                updateCamera={updateCamera}
                                hasPermission={hasPermission}
                            />
                        </FormBox>
                        <FormBox title={"Social Media Beschreibung"}>
                            <CameraMetadataShareForm
                                camera={camera}
                                updateCamera={updateCamera}
                                hasPermission={hasPermission}
                                playerConfig={playerConfig}
                            />
                        </FormBox>
                    </Stack>
                }
            />
            <SidebarAccordion
                title="Audio/Video Quelle"
                disabled={!hasPermission}
                content={
                    hasPermission && (
                        !loading ? (
                            <CameraStreaming 
                                camera={camera}
                                ingest={ingest}
                                playerConfig={playerConfig}
                                updateCamera={updateCamera}
                                hasPermission={hasPermission}
                            />
                        ) : (
                            <CircularProgress color="inherit" size={20} />
                        )
                    )
                }
            />
            <SidebarAccordion
                title="Aufnahmen"
                disabled={!hasPermission}
                content={
                    !loading ? (
                        <CameraRecordingForm
                            camera={camera}
                            ingest={ingest}
                            updateCamera={updateCamera}
                            hasPermission={hasPermission}
                        />
                    ) : (
                        <CircularProgress color="inherit" size={20} />
                    )
                }
            />
            <SidebarAccordion
                last
                title="Protokoll"
                content={
                    <FormBox>
                        <Typography fontSize={14}>
                            <strong>Erstellt:</strong>{" "}
                            <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                {moment(camera.created_at).tz(
                                    moment.tz.guess(),
                                )}
                            </Moment>
                        </Typography>
                        {camera.updated_at && (
                            <Typography fontSize={14}>
                                <strong>Aktualisiert:</strong>{" "}
                                <Moment
                                    format="DD.MM.YYYY HH:mm:ss"
                                    locale="de"
                                >
                                    {moment(camera.updated_at).tz(
                                        moment.tz.guess(),
                                    )}
                                </Moment>
                            </Typography>
                        )}
                    </FormBox>
                }
            />
        </Stack>
    );
}

CameraSidebar.propTypes = {
    drawerWidth: PropTypes.number,
    camera: PropTypes.object.isRequired,
    playerConfig: PropTypes.object.isRequired,
};

CameraSidebar.defaultProps = {
    drawerWidth: 450,
};

export default CameraSidebar;
