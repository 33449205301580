import * as React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

import { useApp } from "../context/App";


export default function CameraMultiSelect({
    name = "",
    label = "",
    fullWidth = true,
    object = {},
    handleChange = () => {},
    hasPermission = false,
}) {
    const { client } = useApp();
    
    const [options, setOptions] = React.useState([]);
    const [inputValues, setInputValues] = React.useState([]);
    const loading = !options || !inputValues;
    
    const handleChangeInput = (newValues) => {
        setInputValues(newValues);
        handleChange({
            target: {
                name: name, 
                value: newValues.map(({ id }) => id),
                type: "input"
            }
        });
    }

    React.useEffect(() => {
        (async () => {
            const data = await client.GetCustomerCamera({hide: "ingest"});
            if (data) {
                const newData = data
                    .map(({ id, meta }) => ({
                        id: id,
                        label: `${meta?.name} ${meta?.city ? `(${meta.city})` : ''}`
                    }))
                    .filter((obj, index, array) => {
                        const currentIndex = array.findIndex(
                            (item) => item.id === obj.id
                        );
                        return currentIndex === index;
                    })
                    .sort((a, b) => (a.label > b.label ? 1 : -1));
                setOptions(newData);
                if (object[name]) {
                    setInputValues(
                        newData.filter(({ id }) => object[name].includes(id))
                    );
                }
            }
        })();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (object[name] && options) {
            setInputValues(
                options.filter(({ id }) => object[name].includes(id))
            );
        }
        // eslint-disable-next-line
    }, [object[name]]);

    return (
        <Autocomplete
            multiple
            size="small"
            variant="standard"
            name={name}
            id="camera-multi-select"
            value={inputValues}
            onChange={(event, newValues) => {
                handleChangeInput(newValues);
            }}
            loading={loading}
            options={options.filter((option) => !inputValues.find(({ id }) => id === option.id))}
            getOptionLabel={(option) => option.label}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                        <Chip
                            key={key}
                            size="small"
                            sx={{
                                margin: 0,
                                textTransform: "none",
                                fontWeight: 500
                            }}
                            label={option.label}
                            {...tagProps}
                        />
                    );
                })
            }
            fullWidth={fullWidth}
            renderInput={(params) => (
                <TextField 
                    {...params} 
                    variant="standard" 
                    label={label} 
                    placeholder="Auswahl..."
                    slotProps={{ 
                        inputLabel: { 
                            shrink: true 
                        },
                        input: {
                            ...params.InputProps,
                            readOnly: !hasPermission,
                        },
                    }}
                />
            )}
        />
    );
};