import React from 'react';
import { Stack } from '@mui/material';

import FormButtonGroup from '../../../../components/FormButtonGroup';
import FormTextField from '../../../../components/FormTextField';
import { removeEmptyStrings, useFormSection } from '../../../../components/misc/useForm';

export default function PlaybackPlayerSidebarIdForm({ 
    player = {},
    updatePlayer = () => {},
    hasPermission = false, 
}) {
    const playerDefault = {
        alias_id: player.alias_id || null,
    };
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: playerUpdate, 
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: playerDefault,
    });

    async function handleUpdate() {    
        updatePlayer({
            player_id: player.id, 
            player: removeEmptyStrings(playerUpdate),
        });
        setIsUpdated(false);
    }

    return (
        <>
            <FormTextField
                name="id"
                label="ID"
                object={{id: player.id}}
            />
            <FormTextField
                name="alias_id"
                label="Alias ID"
                object={playerUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                maxLength={100}
            />
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}