import React from 'react';
import { Checkbox, FormGroup, FormControlLabel, Stack } from '@mui/material';

import FormButtonGroup from '../../../../components/FormButtonGroup';
import { removeEmptyStrings, useFormSection } from '../../../../components/misc/useForm';

export default function PlaybackPlayerSidebarFunctionForm({ 
    player = {},
    updatePlayer = () => {},
    hasPermission = false, 
}) {
    const playerDefault = {
        camera_poster: player.camera_poster || null,
        autoplay: player.autoplay || null,
        mute: player.mute || null,
        chromecast: player.chromecast || null,
        airplay: player.airplay || null,
    };
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: playerUpdate, 
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: playerDefault,
    });

    async function handleUpdate() {    
        updatePlayer({
            player_id: player.id, 
            player: removeEmptyStrings(playerUpdate),
        });
        setIsUpdated(false);
    }

    return (
        <>
            <FormGroup>
                <FormControlLabel
                    name="camera_poster"
                    label="Kamera Poster einblenden"
                    onChange={handleChange}
                    readOnly={!hasPermission}
                    control={
                        <Checkbox
                            checked={playerUpdate.camera_poster ? true : false}
                            color={"blue"}
                        />
                    }
                />
            </FormGroup>
            <FormGroup>
                <FormControlLabel
                    name="autoplay"
                    label="Stream automatisch starten"
                    onChange={handleChange}
                    readOnly={!hasPermission}
                    control={
                        <Checkbox
                            checked={playerUpdate.autoplay ? true : false}
                            color={"blue"}
                        />
                    }
                />
            </FormGroup>
            <FormGroup>
                <FormControlLabel
                    name="mute"
                    label="Ton stummschalten"
                    onChange={handleChange}
                    readOnly={!hasPermission}
                    control={
                        <Checkbox
                            checked={playerUpdate.mute ? true : false}
                            color={"blue"}
                        />
                    }
                />
            </FormGroup>
            <FormGroup>
                <FormControlLabel
                    name="chromecast"
                    label="Chromecast erlauben"
                    onChange={handleChange}
                    readOnly={!hasPermission}
                    control={
                        <Checkbox
                            checked={playerUpdate.chromecast ? true : false}
                            color={"blue"}
                        />
                    }
                />
            </FormGroup>
            <FormGroup>
                <FormControlLabel
                    name="airplay"
                    label="Airplay erlauben"
                    onChange={handleChange}
                    readOnly={!hasPermission}
                    control={
                        <Checkbox
                            checked={playerUpdate.airplay ? true : false}
                            color={"blue"}
                        />
                    }
                />
            </FormGroup>
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}