import * as React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";


function DeleteDialog({
    deleteUser = () => {},
    user = {},
    openDeleteDialog = false,
    handleCloseDeleteDialog = () => {},
}) {
    return (
        <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Zugang löschen"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Möchten den Zugang <strong>{user.name}</strong> wirklich
                    löschen?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="default"
                    color="warning"
                    onClick={handleCloseDeleteDialog}
                >
                    Nein
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteUser({user_id: user.id})}
                    autoFocus
                >
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default function PlaybackUserSidebarSubMenu({ 
    user = null, 
    hasPermission = false,
    updateUser = () => {},
    deleteUser = () => {},
}) {
    const [anchorEl, setAnchorEl] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Delete Dialog
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const handleClickOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    if (!user) {
        return;
    }

    async function handleUpdate(name, value) {   
        updateUser({
            user_id: user.id, 
            user: {[name]: value},
        });
    }

    return (
        <>
            <IconButton
                color="primary"
                size="small"
                id="message-menu-button"
                aria-controls={open ? "message-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="message-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "message-menu-button",
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuList>
                    {/* <MenuItem
                        component={Link}
                        to={`/analytics/playback_user?user_id=${user.id}`}
                        disabled={!hasPermission}
                    >
                        <ListItemIcon>
                            <OpenInNewIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Analytics aufrufen</ListItemText>
                    </MenuItem>
                    <Divider style={{ borderTop: 1 }} /> */}
                    <MenuItem disabled={!hasPermission} onClick={() => handleUpdate("enabled", !user.enabled)}>
                        <ListItemIcon>
                            <ModeEditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{user.enabled ? "Deaktivieren" : "Aktivieren"}</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={handleClickOpenDeleteDialog}
                        disabled={!hasPermission}
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Löschen</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
            <DeleteDialog
                deleteUser={deleteUser}
                user={user}
                openDeleteDialog={openDeleteDialog}
                handleCloseDeleteDialog={handleCloseDeleteDialog}
            />
        </>
    );
}