import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";

function PlaybackUserSidebarCameraSelect({
    cameras,
    selectedCamera,
    setSelectedCamera,
}) {
    const [options, setOptions] = React.useState(null);

    React.useEffect(() => {
        const data = cameras
            .map(({ id, meta }) => ({
                id: id,
                name: meta.name ? meta.name : '',
                city: meta.city ? meta.city : '',
                country: meta.country ? meta.country : '',
            }))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .filter((obj, index, array) => {
                const currentIndex = array.findIndex(
                    (item) => item.id === obj.id,
                );
                return currentIndex === index;
            })
            .sort((a, b) => -(a.country || '').localeCompare(b.country || ''));
        setOptions(data);
    }, [cameras]);

    const selectedCameraValue = (camera) => {
        if (!camera.meta) {
            return camera;
        }
        const { id, meta } = camera;
        return {
            id: id,
            name: meta.name,
            city: meta.city,
            country: meta.country,
        };
    };

    return (
        <Autocomplete
            value={selectedCamera ? selectedCameraValue(selectedCamera) : null}
            size="small"
            id="playback-camera-select"
            options={options ? options : []}
            groupBy={(option) => option.country}
            getOptionLabel={(option) => `${option.name} (${option.city})`}
            onChange={(event, newValue) => {
                setSelectedCamera(newValue);
            }}
            isOptionEqualToValue={(option, value) =>
                option.id === value.id
            }
            fullWidth
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="Kamera Auswahl"
                />
            )}
        />
    );
}

PlaybackUserSidebarCameraSelect.propTypes = {
    cameras: PropTypes.array.isRequired,
    selectedCamera: PropTypes.any,
    setSelectedCamera: PropTypes.any,
};

PlaybackUserSidebarCameraSelect.defaultProps = {};

export default PlaybackUserSidebarCameraSelect;
