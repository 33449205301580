import * as React from "react";
import PropTypes from "prop-types";

import "moment-timezone";
import moment from "moment";

import Divider from "@mui/material/Divider";
import Moment from "react-moment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { DefaultCopyField } from "../../../../components/CopyButton";
import PageSidebarHeader from "../../../../components/PageSidebarHeader";
import FormBox from "../../../../components/FormBox";
import VideoJS from "../../../../components/VideoJS";
import SidebarAccordion from "../../../../components/SidebarAccordion";

import PlaybackPlayerSidebarCameraSelect from "./PlaybackPlayerSidebarCameraSelect";
import PlaybackPlayerSidebarEmbedCodes from "./PlaybackPlayerSidebarEmbedCodes";
import PlaybackPlayerSidebarAccessControlForm from "./PlaybackPlayerSidebarAccessControlForm";
import PlaybackPlayerSidebarLayoutForm from "./PlaybackPlayerSidebarLayoutForm";
import PlaybackPlayerSidebarFunctionForm from "./PlaybackPlayerSidebarFunctionForm";
import PlaybackPlayerSidebarMetadataForm from "./PlaybackPlayerSidebarMetadataForm";
import PlaybackPlayerSidebarIdForm from "./PlaybackPlayerSidebarIdForm";
import PlaybackPlayerSidebarSubMenu from "./PlaybackPlayerSidebarSubMenu";

import { useApp } from "../../../../context/App";

function PlayerConfig({ 
    player = {}, 
    playerConfig = {}, 
    selectedCamera = {
        camera_id: null,
        source: "",
        poster: "",
    },
}) {
    const playerColor = ({ color }) => {
        if (!color) {
            return { seekbar: "#fff", buttons: "#fff" };
        }
        return color;
    };

    const playerGa = ({ analytics }) => {
        if (analytics && analytics.ga_account && analytics.ga_name) {
            return { account: analytics.ga_account, name: analytics.ga_name };
        }
        return null;
    };

    const playerPoster = ({ poster_url, camera_poster }) => {
        if (poster_url && poster_url.length > 0) {
            return playerConfig.url + poster_url;
        } else if (camera_poster) {
            return selectedCamera.poster;
        } else {
            return selectedCamera.poster
                .replace("main.jpg", "main_240.jpg")
                .split("?token=")[0];
        }
    };

    const playerSources = () => {
        return [
            {
                src:
                    selectedCamera.source.split("?token=")[0] +
                    "?token=" +
                    playerConfig.token,
                type: "application/vnd.apple.mpegurl",
            },
        ];
    };

    const videoJsOptions = {
        autoplay: player.autoplay,
        muted: player.mute,
        color: playerColor(player),
        controls: true,
        responsive: true,
        fluid: true,
        logo: player.logo,
        ga: playerGa(player),
        aspectRatio: "16:9",
        poster: playerPoster(player),
        sources: playerSources(),
        playsinline: true,
    };

    return <VideoJS {...videoJsOptions} />;
}


function PlaybackPlayerSidebar({ 
    player = null, 
    updatePlayer = () => {},
    deletePlayer = () => {},
    playerConfig = {},
    drawerWidth = 540,
    handleSidebarToggle = () => {},
    hasPermission = false,
}) {
    const { client } = useApp();
    const customerId = client.CustomerId();
    
    const [playlist, setPlaylist] = React.useState(null);
    const [selectedCamera, setSelectedCamera] = React.useState(null);

    const getPlaylist = async () => {
        const data = await client.GetCustomerPlayerIdPlaylist({
            player_id: player.id,
        });
        setPlaylist(data);
        setSelectedCamera(data[0]);
    };

    React.useEffect(() => {
        getPlaylist();
        // eslint-disable-next-line
    }, [player]);

    if (!player || !playerConfig) {
        return null;
    }

    return (
        <Stack 
            direction="column"
            spacing={0}
            sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
                paddingBottom: 4,
            }}
        >
            <PageSidebarHeader
                title={player.name}
                share={true}
                handleToggle={handleSidebarToggle}
                submenu={
                    <PlaybackPlayerSidebarSubMenu 
                        player={player} 
                        updatePlayer={updatePlayer}
                        deletePlayer={deletePlayer}
                        hasPermission={hasPermission}
                    />
                }
                player={
                    player && playerConfig && selectedCamera && (
                        <PlayerConfig
                            player={player}
                            playerConfig={playerConfig}
                            selectedCamera={selectedCamera}
                        />
                    )
                }
            />
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    width: "100%",
                    paddingBottom: 2,
                    paddingLeft: 2.5,
                    paddingRight: 2.5,
                }}
            >
                <FormBox>
                    <PlaybackPlayerSidebarIdForm
                        player={player}
                        updatePlayer={updatePlayer}
                        hasPermission={hasPermission}
                    />
                </FormBox>
            </Stack>
            <Divider sx={{marginTop: 0, marginBottom: 0}}/>
            <SidebarAccordion
                first
                expanded
                title="Webseiten Integration"
                content={
                    <>
                        {playlist && (
                            <PlaybackPlayerSidebarCameraSelect
                                playlist={playlist.sort((a, b) =>
                                    (a.meta.name || "").localeCompare(
                                        b.meta.name || "",
                                    ),
                                )}
                                selectedCamera={selectedCamera}
                                setSelectedCamera={setSelectedCamera}
                            />
                        )}
                        {selectedCamera && (
                            <>
                                <Typography marginTop={1.5} marginBottom={1.5} fontSize={16} fontWeight={500}>
                                    Nativ (Empfohlen)
                                </Typography>
                                <Stack
                                    style={{
                                        padding: "15px 15px 15px 15px",
                                        marginBottom: 5,
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                        width: "100%",
                                    }}
                                    spacing={2}
                                >
                                    <DefaultCopyField
                                        value={PlaybackPlayerSidebarEmbedCodes({
                                            type: "native",
                                            customerId: customerId,
                                            playerId: player.id,
                                            cameraId: selectedCamera.camera_id,
                                        })}
                                        multiline
                                        maxRows={8}
                                        noWrap
                                    />
                                </Stack>
                                <Typography marginTop={1.5} marginBottom={1.5} fontSize={16} fontWeight={500}>
                                    iFrame (Isoliert)
                                </Typography>
                                <Stack
                                    style={{
                                        padding: "15px 15px 15px 15px",
                                        marginBottom: 5,
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                        width: "100%",
                                    }}
                                    spacing={2}
                                >
                                    <DefaultCopyField
                                        value={PlaybackPlayerSidebarEmbedCodes({
                                            type: "iframe",
                                            customerId: customerId,
                                            playerId: player.id,
                                            cameraId: selectedCamera.camera_id,
                                        })}
                                        multiline
                                        maxRows={3}
                                    />
                                </Stack>
                                <Typography marginTop={1.5} marginBottom={1.5} fontSize={16} fontWeight={500}>
                                    Vorschaubild
                                </Typography>
                                <Stack
                                    style={{
                                        padding: "20px 15px 15px 15px",
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                        width: "100%",
                                    }}
                                    spacing={2}
                                >
                                    <DefaultCopyField
                                        label=""
                                        value={
                                            selectedCamera.poster.split("?token=")[0]
                                        }
                                        multiline
                                        maxRows={3}
                                    />
                                </Stack>
                            </>
                        )}
                    </>
                }
            />
            <SidebarAccordion
                title="Metadaten"
                content={
                    <FormBox>
                        <PlaybackPlayerSidebarMetadataForm
                            player={player}
                            updatePlayer={updatePlayer}
                            hasPermission={hasPermission}
                        />
                    </FormBox>
                }
            />
            <SidebarAccordion
                title="Aussehen"
                content={
                    <FormBox>
                        <PlaybackPlayerSidebarLayoutForm
                            player={player}
                            updatePlayer={updatePlayer}
                            hasPermission={hasPermission}
                        />
                    </FormBox>
                }
            />
            <SidebarAccordion
                title="Funktionen"
                content={
                    <FormBox>
                        <PlaybackPlayerSidebarFunctionForm
                            player={player}
                            updatePlayer={updatePlayer}
                            hasPermission={hasPermission}
                        />
                    </FormBox>
                }
            />
            <SidebarAccordion
                title="Zugriffskontrolle"
                content={
                    <FormBox>
                        <PlaybackPlayerSidebarAccessControlForm
                            player={player}
                            updatePlayer={updatePlayer}
                            hasPermission={hasPermission    }
                        />
                    </FormBox>
                }
            />
            <SidebarAccordion
                last
                title="Protokoll"
                content={
                    <FormBox>
                        <Typography fontSize={14}>
                            <strong>Erstellt:</strong>{" "}
                            <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                {moment(player.created_at).tz(
                                    moment.tz.guess(),
                                )}
                            </Moment>
                        </Typography>
                        {player.updated_at && (
                            <Typography fontSize={14}>
                                <strong>Aktualisiert:</strong>{" "}
                                <Moment
                                    format="DD.MM.YYYY HH:mm:ss"
                                    locale="de"
                                >
                                    {moment(player.updated_at).tz(
                                        moment.tz.guess(),
                                    )}
                                </Moment>
                            </Typography>
                        )}
                    </FormBox>
                }
            />
        </Stack>
    );
}

PlaybackPlayerSidebar.propTypes = {
    drawerWidth: PropTypes.number,
    player: PropTypes.object.isRequired,
    playerConfig: PropTypes.object,
};

PlaybackPlayerSidebar.defaultProps = {
    drawerWidth: 540,
};

export default PlaybackPlayerSidebar;
