import * as React from 'react';
import { Stack } from '@mui/material';

import CameraMultiSelect from '../../../components/CameraMultiSelect';
import FormAddButton from '../../../components/FormAddButton';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormTextField from '../../../components/FormTextField';
import { removeEmptyStrings, useFormSection, validEmail} from '../../../components/misc/useForm';

export default function AdminACLAddButton({
    createAcl = () => {},
    hasPermission = false, 
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const aclCreateDefault = {
        name: "",
        description: "",
        cameras: [],
    };

    const { 
        isUpdated,
        setIsUpdated,
        formUpdate: aclCreate,
        setFormUpdate: setAclCreate,
        handleChange,
    } = useFormSection({
        formDefault: aclCreateDefault,
    });

    function handleReset() {
        setAclCreate((prev) => {
            return {
                ...prev,
                ...aclCreateDefault,
            };
        });
        setIsUpdated(false);
        handleClose();
    }

    async function handleCreate() {    
        createAcl({
            acl: removeEmptyStrings(aclCreate),
        });
        handleReset();
        handleClose();
    }

    const validForm = (
        aclCreate.name.length > 0
        && aclCreate.description.length > 0
        && aclCreate.cameras.length > 0
    );

    return (
        <FormAddButton
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            handleClick={handleClick}
            hasPermission={hasPermission}
        >
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    padding: 4,
                    width: 300,
                }}
            >
                <FormTextField
                    name="name"
                    label="Name"
                    object={aclCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={100}
                />
                <FormTextField
                    name="description"
                    label="Beschreibung"
                    object={aclCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={255}
                />
                <CameraMultiSelect
                    name="cameras"
                    label="Kameras"
                    object={aclCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                />
                {hasPermission && isUpdated && (
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            justifyContent: "flex-end",
                            alignItems: "center",
                            width: "100%",
                            paddingTop: 1,
                        }}
                    >
                        <FormButtonGroup 
                            handleReset={handleReset}
                            handleUpdate={handleCreate}
                            isValid={validForm}
                            spaceBetween
                        />
                    </Stack>
                )}
            </Stack>
        </FormAddButton>
    );
}