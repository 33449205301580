import React from 'react';

import FormTextField from '../../../../components/FormTextField';


export default function AdminACLSidebarIdForm({
    acl = {},
}) {

    const aclDefault = {
        id: acl.id || null,
    };

    return (
        <>
            <FormTextField
                name="id"
                label="ID"
                object={aclDefault}
                readOnly
            />
        </>
    );
}