import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import Page from "../../components/Page";
import SearchField from "../../components/SearchField";

import PlaybackUser from "./PlaybackUser";
import PlaybackUserSidebar from "./PlaybackUser/PlaybackUserSidebar";
import PlaybackUserAddButton from "./PlaybackUser/PlaybackUserAddButton";

import { useApp } from "../../context/App";

function Player(props) {
    const { rootPath } = props;
    const { client, hasPermission, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PLAYER OBJECT
    const [playerConfig, setPlayerConfig] = React.useState(false);
    const getPlayerConfig = async () => {
        if (!playerConfig) {
            const data = client.PlayerConfig();
            setPlayerConfig(data);
        }
        return playerConfig;
    };

    // PERMISSION CHECK
    const playbackPlayerAdminRoles = ["admin", "player:admin"];
    const playbackPlayerEditorRoles = playbackPlayerAdminRoles.concat([
        "editor",
        "player:editor",
    ]);

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        navigate(rootPath);
    };

    // PLAYBACK USER VALUES
    const navigate = useNavigate();
    const { user_id } = useParams();
    const [users, setUsers] = React.useState(null);
    const [user, setUser] = React.useState(null);

    const getUsers = async () => {
		const data = await client.GetCustomerPlaybackUser({});
		if (data) {
			setUsers(data);
		}
	};

	const createUser = async ({
		user = null,
	}) => {
		if (!user) return;
		let data = null;
		data = await client.PostCustomerPlaybackUser({
			playback_user: user
		});
		if (data) {
			setUser(data);
			setUsers([...users, data]);
            navigate(`${rootPath}/${data.id}`);
		}
	};

	const updateUser = async ({
		user_id = null,
		user = null,
	}) => {
		if (!user_id || !user) return;
		let data = null;
		data = await client.PutCustomerPlaybackUserId({
            playback_user_id: user_id, playback_user: user
        });
		if (data) {
			setUser(data);
			setUsers(users.map((item) => (item.id === user_id ? data : item)));
		}
	};

	const deleteUser = async ({
		user_id = null,
	}) => {
		if (!user_id) return;
		await client.DeleteCustomerPlaybackUserId({
			playback_user_id: user_id
		});
		setUsers(users.filter(item => item.id !== user_id));
		navigate(rootPath);
	};

    React.useEffect(() => {
        (async () => {
            setSelectedPage("playback_user");
            await getPlayerConfig();
            await getUsers();
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
    }, [setSelectedPage, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    React.useEffect(() => {
        if (users && user_id) {
            const data = users.find((user) => user.id === user_id);
            if (!data) {
                navigate(rootPath);
            } else {
                setUser(data);
                setOpenSidebar(true);
            }
        } else {
            setUser(null);
            setOpenSidebar(false);
        }
    }, [users, user_id]);

    const drawerWidth = 480;
    const [searchText, setSearchText] = React.useState("");

    const breadcrumbs = () => {
		if (user) {
            return [
                {title: 'Wiedergabe', link: '#'},
                {title: 'Direktzugriff', link: rootPath},
                {title: `${user.name}`, link: `${rootPath}/${user.id}`}
            ]
        } else {
            return [
                {title: 'Wiedergabe', link: '#'},
                {title: 'Direktzugriff', link: rootPath}
            ]
        }
	}

    return (
        <Page
            pageTitel="Direktzugriff"
            drawerWidth={drawerWidth}
            breadcrumbs={breadcrumbs ? breadcrumbs() : []}
            headerContentRight={
                <>
                    <SearchField
                        outlined
                        searchText={searchText}
                        setSearchText={setSearchText} 
                    />
                    <PlaybackUserAddButton
                        openSidebar={openSidebar}
                        handleSidebarToggle={handleSidebarToggle}
                        hasPermission={hasPermission(playbackPlayerAdminRoles)}
                        createUser={createUser}
                    />
                </>
            }
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            sidebarContent={
                user && (
                    <PlaybackUserSidebar
                        key={user.id}
                        user={user}
                        updateUser={updateUser}
                        deleteUser={deleteUser}
                        playerConfig={playerConfig}
                        drawerWidth={drawerWidth}
                        handleSidebarToggle={handleSidebarToggle}
                        hasPermission={hasPermission(playbackPlayerEditorRoles)}
                    />
                )
            }
        >
            {users && (
                <PlaybackUser
                    rootPath={rootPath}
                    users={users}
                    user_id={user_id}
                    searchText={searchText}
                    navigate={navigate}
                />
            )}
        </Page>
    );
}

Player.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

Player.defaultProps = {
    openMenu: false,
};

export default Player;
