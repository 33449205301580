import React from 'react';
import { Stack } from '@mui/material';

import ACLSelect from '../../../../components/ACLSelectV2';
import FormButtonGroup from '../../../../components/FormButtonGroup';
import FormTextField from '../../../../components/FormTextField';
import { removeEmptyStrings, useFormSection } from '../../../../components/misc/useForm';


export default function UserPermissionRolesForm({
    user = {},
    updateUser = () => {},
    hasPermission = false, 
}) {

    const userDefault = {
        acl_id: user.acl_id || null,
        permission_roles: user.permission_roles || [],
    };

    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: userUpdate, 
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: userDefault,
    });

    async function handleUpdate() {    
        updateUser({
            username: user.username, 
            user: removeEmptyStrings(userUpdate)
        });
        setIsUpdated(false);
    }

    const permissionRoles = [
        {
            value: 'admin',
            label: 'Administrator',
        },
        {
            value: 'viewer',
            label: 'Uneingeschränkt (Lesen)',
        },
        {
            value: 'editor',
            label: 'Uneingeschränkt (Bearbeiten)',
        },
        {
            value: 'billing',
            label: 'Abrechnung',
        },
        {
            value: 'camera:viewer',
            label: 'Kamera (Lesen)',
        },
        {
            value: 'camera:editor',
            label: 'Kamera (Bearbeiten)',
        },
        {
            value: 'camera:admin',
            label: 'Kamera (Verwalten)',
        },
        {
            value: 'restream:viewer',
            label: 'Restreaming (Lesen)',
        },
        {
            value: 'restream:editor',
            label: 'Restreaming (Bearbeiten)',
        },
        {
            value: 'restream:admin',
            label: 'Restreaming (Verwalten)',
        },
        {
            value: 'playout:viewer',
            label: 'Playout (Lesen)',
        },
        {
            value: 'playout:editor',
            label: 'Playout (Bearbeiten)',
        },
        {
            value: 'playout:admin',
            label: 'Playout (Verwalten)',
        },
        {
            value: 'video:viewer',
            label: 'Video (Lesen)',
        },
        {
            value: 'video:editor',
            label: 'Video (Bearbeiten)',
        },
        {
            value: 'video:admin',
            label: 'Video (Verwalten)',
        },
        {
            value: 'player:viewer',
            label: 'Player/Direktzugriff (Lesen)',
        },
        {
            value: 'player:editor',
            label: 'Player/Direktzugriff (Bearbeiten)',
        },
        {
            value: 'player:admin',
            label: 'Player/Direktzugriff (Verwalten)',
        },
        {
            value: 'analytics:viewer',
            label: 'Analytics (Lesen)',
        }
    ];

    const isValid = userUpdate.permission_roles.length > 0;

    return (
        <>
            <ACLSelect
                name="acl_id"
                label="Zugriffskontrollliste (ACL)"
                placeholder="Keine Einschränkung"
                object={userUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
            />
            <FormTextField
                name="permission_roles"
                label="Zugriffsrechte (CM)"
                object={userUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                select
                selectOptions={permissionRoles}
                multiSelect
            />
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                        isValid={isValid}
                    />
                </Stack>
            )}
        </>
    );
}