import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

import { useApp } from "../context/App";

export default function ACLSelect({
    name = "",
    label = "",
    fullWidth = true,
    object = {},
    handleChange = () => {},
    hasPermission = false,
}) {
    const { client } = useApp();

    const [options, setOptions] = React.useState([]);
    const loading = options.length === 0;
    const [inputValue, setInputValue] = React.useState("");

    const getValue = () => {
        if (options.length === 0) {
            return null;
        }
        return options.find((opt) => opt.id === object[name]) || null
    };
    
    React.useEffect(() => {
        (async () => {
            const data = await client.GetCustomerACL();
            if (data) {
                setOptions(data.sort((a, b) => a.name.localeCompare(b.name)));
            }
        })();
        // eslint-disable-next-line
    }, []);


    return (
        <Autocomplete
            name={name}
            value={getValue()}
            onChange={(event, newValue) => {
                const newId = newValue ? newValue.id : null;
                handleChange({target:{name: name, value: newId, type: "input"}});
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            id="audio-playlist-select"
            size="small"
            isOptionEqualToValue={(option, value) => option.id === value}
            getOptionLabel={(option) => option.name}
            readOnly={!hasPermission}
            options={options}
            loading={loading}
            fullWidth={fullWidth}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label}
                    placeholder="Keine Einschränkung"
                    sx={"width: 100%"}
                    slotProps={{ 
                        inputLabel: { 
                            shrink: true 
                        },
                        input: {
                            ...params.InputProps,
                            readOnly: !hasPermission,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        },
                    }}
                />
            )}
        />
    );
}