import React from 'react';
import { Stack } from '@mui/material';

import FormButtonGroup from '../../../../components/FormButtonGroup';
import FormTextField from '../../../../components/FormTextField';
import { removeEmptyStrings, useFormSection } from '../../../../components/misc/useForm';


export default function PlaybackUserSidebarMetadataForm({ 
    user = {},
    updateUser = () => {},
    hasPermission = false, 
}) {
    const userDefault = {
        name: user.name || "",
        description: user.description || "",
    };
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: userUpdate, 
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: userDefault,
    });

    async function handleUpdate() {    
        updateUser({
            user_id: user.id, 
            user: removeEmptyStrings(userUpdate),
        });
        setIsUpdated(false);
    }

    return (
        <>
            <FormTextField
                name="name"
                label="Name"
                object={userUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                minLength={3}
                maxLength={100}
            />
            <FormTextField
                name="description"
                label="Beschreibung"
                object={userUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                maxLength={255}
            />
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}