import React from 'react';
import { Stack } from '@mui/material';

import CameraMultiSelect from '../../../../components/CameraMultiSelect';
import FormButtonGroup from '../../../../components/FormButtonGroup';
import FormTextField from '../../../../components/FormTextField';
import { removeEmptyStrings, useFormSection } from '../../../../components/misc/useForm';


export default function AdminACLSidebarCameraForm({
    acl = {},
    updateAcl = () => {},
    hasPermission = false, 
}) {

    const aclDefault = {
        cameras: acl.cameras || [],
    };

    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: aclUpdate, 
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: aclDefault,
    });

    async function handleUpdate() {    
        updateAcl({
            acl_id: acl.id, 
            acl: removeEmptyStrings(aclUpdate)
        });
        setIsUpdated(false);
    }

    const formIsValid = aclUpdate.cameras.length > 0;

    return (
        <>
            <CameraMultiSelect
                name="cameras"
                label=""
                object={aclUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
            />
            {hasPermission && isUpdated && formIsValid && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                        isValid={formIsValid}
                    />
                </Stack>
            )}
        </>
    );
}