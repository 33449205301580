import * as React from 'react';
import { Checkbox, FormGroup, FormControlLabel, Stack } from '@mui/material';

import ACLSelect from '../../../components/ACLSelectV2';
import FormAddButton from '../../../components/FormAddButton';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormTextField from '../../../components/FormTextField';
import { removeEmptyStrings, useFormSection, validEmail } from '../../../components/misc/useForm';

export default function AdminUserAddButton({
    createUser = () => { },
    hasPermission = false,
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const userCreateDefault = {
        first_name: "",
        last_name: "",
        company: "",
        email: "",
        acl_id: null,
        permission_roles: ["viewer"],
        type: "user",
    };

    const {
        isUpdated,
        setIsUpdated,
        formUpdate: userCreate,
        setFormUpdate: setUserCreate,
        handleChange,
    } = useFormSection({
        formDefault: userCreateDefault,
    });

    const [isApi, setIsApi] = React.useState(false);
    const handleIsApi = () => {
        setIsApi(!isApi);
        userCreate.type = isApi ? "application" : "user";
    }

    function handleReset() {
        setUserCreate((prev) => {
            return {
                ...prev,
                ...userCreateDefault,
            };
        });
        setIsUpdated(false);
        handleClose();
    }

    async function handleCreate() {
        createUser({
            user: removeEmptyStrings(userCreate),
        });
        handleReset();
        handleClose();
    }

    const permissionRoles = [
        {
            value: 'admin',
            label: 'Administrator',
        },
        {
            value: 'viewer',
            label: 'Uneingeschränkt (Lesen)',
        },
        {
            value: 'editor',
            label: 'Uneingeschränkt (Bearbeiten)',
        },
        {
            value: 'billing',
            label: 'Abrechnung',
        },
        {
            value: 'camera:viewer',
            label: 'Kamera (Lesen)',
        },
        {
            value: 'camera:editor',
            label: 'Kamera (Bearbeiten)',
        },
        {
            value: 'camera:admin',
            label: 'Kamera (Verwalten)',
        },
        {
            value: 'restream:viewer',
            label: 'Restreaming (Lesen)',
        },
        {
            value: 'restream:editor',
            label: 'Restreaming (Bearbeiten)',
        },
        {
            value: 'restream:admin',
            label: 'Restreaming (Verwalten)',
        },
        {
            value: 'playout:viewer',
            label: 'Playout (Lesen)',
        },
        {
            value: 'playout:editor',
            label: 'Playout (Bearbeiten)',
        },
        {
            value: 'playout:admin',
            label: 'Playout (Verwalten)',
        },
        {
            value: 'video:viewer',
            label: 'Video (Lesen)',
        },
        {
            value: 'video:editor',
            label: 'Video (Bearbeiten)',
        },
        {
            value: 'video:admin',
            label: 'Video (Verwalten)',
        },
        {
            value: 'player:viewer',
            label: 'Player/Direktzugriff (Lesen)',
        },
        {
            value: 'player:editor',
            label: 'Player/Direktzugriff (Bearbeiten)',
        },
        {
            value: 'player:admin',
            label: 'Player/Direktzugriff (Verwalten)',
        },
        {
            value: 'analytics:viewer',
            label: 'Analytics (Lesen)',
        }
    ];

    const emailIsValid = validEmail(userCreate.email);
    const validForm = (
        userCreate.first_name.length > 0
        && userCreate.last_name.length > 0
        && userCreate.company.length > 0
        && emailIsValid
        && userCreate.permission_roles.length > 0
    );

    return (
        <FormAddButton
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            handleClick={handleClick}
            hasPermission={hasPermission}
        >
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    padding: 4,
                    width: 400,
                }}
            >
                <FormTextField
                    name="first_name"
                    label="Vorname"
                    object={userCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={100}
                />
                <FormTextField
                    name="last_name"
                    label="Nachname"
                    object={userCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={100}
                />
                <FormTextField
                    name="company"
                    label="Firma"
                    object={userCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={100}
                />
                <FormTextField
                    name="email"
                    label="E-Mail"
                    object={userCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={100}
                    error={!emailIsValid}
                    helperText={!emailIsValid ? "Bitte eine gültige E-Mail-Adresse eingeben" : ""}
                />
                <ACLSelect
                    name="acl_id"
                    label="Zugriffskontrollliste (ACL)"
                    placeholder="Keine Einschränkung"
                    object={userCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                />
                <FormTextField
                    name="permission_roles"
                    label="Zugriffsrechte (CM)"
                    object={userCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    select
                    selectOptions={permissionRoles}
                    multiSelect
                />
                <FormGroup>
                    <FormControlLabel
                        name="is_api"
                        label="Auf API beschränken (M2M)"
                        onChange={handleIsApi}
                        readOnly={!hasPermission}
                        control={
                            <Checkbox
                                checked={isApi}
                                color={"blue"}
                            />
                        }
                    />
                </FormGroup>
                {hasPermission && isUpdated && (
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            justifyContent: "flex-end",
                            alignItems: "center",
                            width: "100%",
                            paddingTop: 1,
                        }}
                    >
                        <FormButtonGroup
                            handleReset={handleReset}
                            handleUpdate={handleCreate}
                            isValid={validForm}
                            spaceBetween
                        />
                    </Stack>
                )}
            </Stack>
        </FormAddButton>
    );
}