import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Stack } from '@mui/material';

export default function FormButtonGroup({
    handleReset = () => {},
    handleUpdate = () => {},
    isValid = true,
    spaceBetween = false,
}) {
    if (spaceBetween) {
        return (
            <Stack 
                direction="row"
                spacing={0}
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    paddingTop: 1,
                }}
            >
                <Button
                    size='small'
                    onClick={handleReset}
                    sx={{
                        background: 'white',
                        color: '#000',
                        border: '1px solid rgba(0,0,0,.2)',
                        "&:hover": {
                            color: '#fff',
                            background: 'rgba(219, 46, 51, 0.9)',
                            border: "1px solid transparent",
                        }
                    }}
                >
                    Abbrechen
                </Button>
                <Button
                    size='small'
                    onClick={handleUpdate}
                    disabled={!isValid}
                    sx={{
                        background: 'white',
                        color: '#168d1a',
                        border: '1px solid rgba(0,0,0,.2)',
                        "&:hover": {
                            color: '#fff',
                            background: '#168d1a',
                            border: "1px solid transparent",
                        }
                    }}
                >
                    <CheckCircleIcon sx={{marginRight: 1}} /> Speichern
                </Button>
            </Stack>
        );
    } else {
        return (
            <ButtonGroup 
                size="small" 
                color="success"
                sx={{
                    border: '1px solid rgba(0,0,0,.2)',
                    "& .MuiButtonGroup-firstButton": {
                        borderRight: "2px solid rgba(0,0,0,.2)",
                    },
                }}
            >
                <Button
                    onClick={handleReset}
                    sx={{
                        background: 'white',
                        color: '#000',
                        border: "1px solid transparent",
                        "&:hover": {
                            color: '#fff',
                            background: 'rgba(219, 46, 51, 0.9)',
                        }
                    }}
                >
                    Abbrechen
                </Button>
                <Button
                    onClick={handleUpdate}
                    disabled={!isValid}
                    sx={{
                        background: 'white',
                        color: '#168d1a',
                        border: "1px solid transparent",
                        "&:hover": {
                            color: '#fff',
                            background: '#168d1a',
                        }
                    }}
                >
                    <CheckCircleIcon sx={{marginRight: 1}} /> Speichern
                </Button>
            </ButtonGroup>
        );
    }
}