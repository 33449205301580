import * as React from "react";

import "moment-timezone";
import moment from "moment";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import FormBox from "../../../../components/FormBox";
import PageSidebarHeader from "../../../../components/PageSidebarHeader";
import SidebarAccordion from "../../../../components/SidebarAccordion";

import AdminACLSidebarMenu from "./AdminACLSidebarMenu";
import AdminACLSidebarIdForm from "./AdminACLSidebarIdForm";
import AdminACLSidebarMetaForm from "./AdminACLSidebarMetaForm";
import AdminACLSidebarCameraForm from "./AdminACLSidebarCameraForm";
import AdminACLSidebarLinks from "./AdminACLSidebarLinks";

import { useApp } from "../../../../context/App";


export default function AdminACLSidebar({ 
    acl = {},
    updateAcl = () => {},
    deleteAcl = () => {},
    hasPermission = false,
    drawerWidth = 300,
    handleSidebarToggle = () => {},
}) {

    const { client } = useApp();
    const [link, setLink] = React.useState(null);
    const loading = !link;

    React.useEffect(() => {
        if (!loading) {
            return undefined;
        }

        (async () => {
            if (!link) {
                client
                    .GetCustomerACLIdLink({ acl_id: acl.id })
                    .then((data) => setLink(data));
            }
        })();
        // eslint-disable-next-line
    }, [link]);

    return (
        <Stack 
            direction="column"
            spacing={0}
            sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
                paddingBottom: 4,
            }}
        >
            <PageSidebarHeader
                title={acl.name}
                share={true}
                handleToggle={handleSidebarToggle}
                submenu={
                    <AdminACLSidebarMenu 
                        acl={acl}
                        link={link}
                        deleteAcl={deleteAcl}
                        hasPermission={hasPermission}
                    />
                }
            />
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    width: "100%",
                    paddingBottom: 2,
                    paddingLeft: 2.5,
                    paddingRight: 2.5,
                }}
            >
                <FormBox>
                    <AdminACLSidebarIdForm
                        acl={acl}
                    />
                </FormBox>
            </Stack>
            <Divider sx={{ marginTop: 0, marginBottom: 0 }} />
            <SidebarAccordion
                first
                expanded
                title="Verwendung"
                content={
                    <AdminACLSidebarLinks 
                        link={link} 
                    />
                }
            />
            <SidebarAccordion
                title="Freigegebene Kameras"
                content={
                    <FormBox>
                        <AdminACLSidebarCameraForm
                            acl={acl}
                            updateAcl={updateAcl}
                            hasPermission={hasPermission}
                        />
                    </FormBox>
                }
            />
            <SidebarAccordion
                last
                title="Metadaten"
                content={
                    <FormBox>
                        <AdminACLSidebarMetaForm
                            acl={acl}
                            updateAcl={updateAcl}
                            hasPermission={hasPermission}
                        />
                    </FormBox>
                }
            />
            {/* <SidebarAccordion
                last
                title="Protokoll"
                content={
                    <FormBox>
                        <Typography fontSize={14}>
                            <strong>Erstellt:</strong>{" "}
                            <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                {moment(acl.created_at).tz(moment.tz.guess())}
                            </Moment>
                        </Typography>
                        {acl.updated_at && (
                            <Typography fontSize={14}>
                                <strong>Aktualisiert:</strong>{" "}
                                <Moment
                                    format="DD.MM.YYYY HH:mm:ss"
                                    locale="de"
                                >
                                    {moment(acl.updated_at).tz(
                                        moment.tz.guess(),
                                    )}
                                </Moment>
                            </Typography>
                        )}
                    </FormBox>
                }
            /> */}
        </Stack>
    );
};