import React from 'react';
import { Stack } from '@mui/material';

import ACLSelect from '../../../../components/ACLSelectV2';
import FormButtonGroup from '../../../../components/FormButtonGroup';
import FormTextField from '../../../../components/FormTextField';
import { removeEmptyStrings, useFormSection } from '../../../../components/misc/useForm';

export default function PlaybackUserSidebarAccessControlForm({ 
    user = {},
    updateUser = () => {},
    hasPermission = false, 
}) {
    const userDefault = {
        acl_id: user.acl_id || null,
        protocol_allowlist: user.protocol_allowlist || ["HLS"],
    };
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: userUpdate, 
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: userDefault,
    });

    async function handleUpdate() {    
        updateUser({
            user_id: user.id, 
            user: removeEmptyStrings(userUpdate),
        });
        setIsUpdated(false);
    }

    const protocol_allowlist = [
        { value: "hls", label: "HLS" },
        { value: "rtmp", label: "RTMP" },
        { value: "srt", label: "SRT" },
    ];

    const protocolAllowlistisInvalid = userUpdate.protocol_allowlist.length === 0;
    const validForm = !protocolAllowlistisInvalid;

    return (
        <>
            <ACLSelect
                name="acl_id"
                label="Zugriffskontrollliste (ACL)"
                placeholder="Keine Einschränkung"
                object={userUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
            />
            <FormTextField
                name="protocol_allowlist"
                label="Erlaubte Protokolle"
                object={userUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                multiSelect
                select
                selectOptions={protocol_allowlist}
                error={protocolAllowlistisInvalid}
                helperText={protocolAllowlistisInvalid ? "Mindestens ein Protokoll muss ausgewählt sein." : ""}
            />
            {hasPermission && isUpdated && validForm && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}