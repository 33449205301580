import * as React from "react";

import PropTypes from "prop-types";

import { useApp } from "../../context/App";

import UserForm from "./UserForm";
import UserPasswordForm from "./UserPasswordForm";


export default function UserDialog({
    user = {},
    form_type = "user_form",
    open = false,
    handleClose = () => { },
}) {
    const { client } = useApp();

    // USER DATA
    const [userData, setUserData] = React.useState(null);

    const getUser = async () => {
        const data = await client.GetUser({
            email: user.email
        });
        if (data) {
            setUserData((prev) => { return data });
        }
    };

    const updateUser = async ({
        user = null,
        user_avatar_image = null,
        user_password_change = null,
    }) => {
        if (!user && !user_avatar_image && !user_password_change) return;
        let data = null;
        if (user) {
            data = await client.PutUser({
                data: user,
            });
        }
        if (user_avatar_image) {
            data = await client.PutUserAvatar({
                image: user_avatar_image,
            });
        }
        if (user_password_change) {
            await client.PutUserPasswordChange(user_password_change);
        }
        if (data && data.first_name && data.last_name) {
            client.UserConfigUpdate({
                first_name: user.first_name, last_name: user.last_name, avatar_url: user.avatar_url,
            });
            setUserData((prev) => { return data });
        }
    };

    React.useEffect(() => {
        getUser();
    }, []);

    return (
        userData && (
            form_type === "user_form" ?
                <UserForm
                    user={userData}
                    updateUser={updateUser}
                    open={open}
                    handleClose={handleClose}
                />
                :
                <UserPasswordForm
                    user={userData}
                    updateUser={updateUser}
                    open={open}
                    handleClose={handleClose}
                />
        )
    );
}

UserDialog.propTypes = {
    user: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};
