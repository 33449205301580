import React from 'react';
import { Stack } from '@mui/material';

import ImageUploadField from '../../../../components/ImageUploadField';
import FormButtonGroup from '../../../../components/FormButtonGroup';
import FormTextField from '../../../../components/FormTextField';
import { removeEmptyStrings, useFormSection, validHttpUrl, validHexColor } from '../../../../components/misc/useForm';

export default function FormLayout({ 
    player = {},
    updatePlayer = () => {},
    hasPermission = false, 
}) {
    const playerDefault = {
        theme: player.theme || null,
        aspect_ratio: player.aspect_ratio || "8",
        poster_url: player.poster_url || null,
    };

    const playerColorDefault = {
        seekbar: player.color?.seekbar || "#FFFFFF",
        buttons: player.color?.buttons || "#FFFFFF",
    };

    const playerLogoDefault = {
        image: player.logo?.image || null,
        link: player.logo?.link || null,
        position: player.logo?.position || "top-right",
    };

    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: playerUpdate, 
        setFormUpdate: setPlayerUpdate,
        handleChange,
    } = useFormSection({
        formDefault: playerDefault,
    });

    const [posterImage, setPosterImage] = React.useState(null);
    React.useEffect(() => {
        if (posterImage && posterImage.data !== playerDefault.poster_url) {
            setIsUpdated(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [posterImage]);

    const [playerColorUpdate, setPlayerColorUpdate] = React.useState(playerColorDefault);
    
    const [playerLogoUpdate, setPlayerLogoUpdate] = React.useState(playerLogoDefault);
    const [logoImage, setLogoImage] = React.useState(null);
    React.useEffect(() => {
        if (logoImage && logoImage.data !== playerLogoUpdate.image) {
            setIsUpdated(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoImage]);

    function handleChangeColor(e) {
        setPlayerColorUpdate({
            ...playerColorUpdate,
            [e.target.name]: e.target.value
        });
        setIsUpdated(true);
    }

    function handleChangeLogo(e) {
        setPlayerLogoUpdate({
            ...playerLogoUpdate,
            [e.target.name]: e.target.value
        });
        setIsUpdated(true);
    }

    function handleReset() {
        setPlayerUpdate((prev) => {
            return {
                ...prev,
                ...playerDefault,
            };
        });
        setPlayerColorUpdate((prev) => {
            return {
                ...prev,
                ...playerColorDefault,
            };
        });
        setPlayerLogoUpdate((prev) => {
            return {
                ...prev,
                ...playerLogoDefault,
            };
        });
        setLogoImage((prev) => {
            return null
        });
        setPosterImage((prev) => {
            return null
        });
        setIsUpdated(false);
    }

    async function handleUpdate() {  
        if (playerColorUpdate.seekbar || playerColorUpdate.buttons) {
            playerUpdate.color = removeEmptyStrings(playerColorUpdate);
        }
        if (playerLogoUpdate.image) {
            playerUpdate.logo = removeEmptyStrings(playerLogoUpdate);
        }
        updatePlayer({
            player_id: player.id, 
            player: removeEmptyStrings(playerUpdate),
        });
        setIsUpdated(false);
    }

    const themes = [
        {
            value: 'default',
            label: 'Standard',
        },
    ];

    const aspect_ratio = [
        {
            value: 'fluid',
            label: 'Responsive',
        },
        {
            value: '16:9',
            label: '16:9',
        },
        {
            value: '4:3',
            label: '4:3',
        },
    ];

    const logo_positions = [
        {
            value: 'top-left',
            label: 'Oben links',
        },
        {
            value: 'top-right',
            label: 'Oben rechts',
        },
        {
            value: 'bottom-left',
            label: 'Unten links',
        },
        {
            value: 'bottom-right',
            label: 'Unten rechts',
        },
    ];

    const domainIsInvalid = playerLogoUpdate.link && !validHttpUrl(playerLogoUpdate.link);
    const seekbarIsInvalid = playerColorUpdate.seekbar && !validHexColor(playerColorUpdate.seekbar);
    const buttonsIsInvalid = playerColorUpdate.buttons && !validHexColor(playerColorUpdate.buttons);

    return (
        <>
            <FormTextField
                name="theme"
                label="Template"
                object={playerUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                select
                selectOptions={themes}
            />
            <Stack direction="row" spacing={2} alignItems="center" width={"100%"}>
                <FormTextField
                    name="seekbar"
                    label="Farbe der Leiste (Hex)"
                    placeholder='#FFFFFF'
                    object={playerColorUpdate}
                    handleChange={handleChangeColor}
                    hasPermission={hasPermission}
                    maxLength={7}
                    error={seekbarIsInvalid}
                    helperText={seekbarIsInvalid ? "Ungültiger Farbwert" : ""}
                />
                <FormTextField
                    name="buttons"
                    label="Farbe der Knöpfe (Hex)"
                    placeholder='#FFFFFF'
                    object={playerColorUpdate}
                    handleChange={handleChangeColor}
                    hasPermission={hasPermission}
                    maxLength={7}
                    error={buttonsIsInvalid}
                    helperText={buttonsIsInvalid ? "Ungültiger Farbwert" : ""}
                />
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" width={"100%"}>
                <ImageUploadField
                    id="image"
                    uploadedFile={logoImage?.data || playerLogoUpdate.image}
                    setUploadedFile={setLogoImage}
                    width={72}
                    height={42}
                    readOnly={!hasPermission}
                />
                <FormTextField
                    name="image"
                    label="Logo"
                    object={playerLogoUpdate}
                    handleChange={handleChangeLogo}
                    hasPermission={hasPermission}
                />
            </Stack>
            <FormTextField
                name="position"
                label="Logo Position"
                object={playerLogoUpdate}
                handleChange={handleChangeLogo}
                hasPermission={hasPermission}
                select
                selectOptions={logo_positions}
            />
            <FormTextField
                name="link"
                label="Logo Verlinkung"
                object={playerLogoUpdate}
                handleChange={handleChangeLogo}
                hasPermission={hasPermission}
                maxLength={200}
                placeholder='https://beispiel.de'
                error={domainIsInvalid}
                helperText={domainIsInvalid ? "Bitte eine gültige URL eingeben" : ""}
            />
            <Stack direction="row" spacing={2} alignItems="center" width={"100%"}>
                <ImageUploadField
                    id="poster_url"
                    uploadedFile={posterImage?.data || playerUpdate.poster_url}
                    setUploadedFile={setPosterImage}
                    width={72}
                    height={42}
                    readOnly={!hasPermission}
                />
                <FormTextField
                    name="poster_url"
                    label="Poster"
                    object={playerUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                />
            </Stack>
            <FormTextField
                name="aspect_ratio"
                label="Format (Seitenverhältnis)"
                object={playerUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                select
                selectOptions={aspect_ratio}
            />
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}