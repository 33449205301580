import * as React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import FormBox from "../FormBox";
import FormTextField from '../FormTextField';
import FormDialog from "../FormDialog";
import { useFormSection, } from '../misc/useForm';
import UserAvatarUpload from "./UserAvatarUpload";


export default function UserPasswordForm({
    user = {},
    updateUser = () => { },
    open = false,
    handleClose = () => { },
}) {
    const theme = useTheme();
    const downMd = useMediaQuery(theme.breakpoints.down("md"));
    const hasPermission = true;

    const userDefault = {
        old_password: "",
        password: "",
        password_confirm: "",
    };

    const {
        isUpdated,
        setIsUpdated,
        formUpdate: userUpdate,
        setFormUpdate: setUserUpdate,
        handleChange,
    } = useFormSection({
        formDefault: userDefault,
    });

    const handleReset = () => {
        setUserUpdate((prev) => {
            return userDefault;
        });
        setIsUpdated(false);
        handleClose();
    };

    const handleUpdate = () => {
        updateUser({
            user_password_change: userUpdate
        });
        setIsUpdated(false);
        handleClose();
    };

    const disablePasswordChange = () => {
        return userUpdate.old_password === '' || userUpdate.password === '' || userUpdate.password !== userUpdate.password_confirm || userUpdate.password.length < 12 || userUpdate.password.length > 36;
    }

    return (
        <FormDialog
            open={open}
            handleClose={handleClose}
            dialogTitle="Passwort ändern"
            dialogContent={
                <FormBox>
                    <Stack
                        direction={downMd ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={3}
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            spacing={2}
                            sx={{
                                marginTop: 1,
                                marginBottom: 2,
                                width: "100%",
                            }}
                        >
                            <FormTextField
                                name="old_password"
                                label="Aktuelles Passwort"
                                password
                                object={userUpdate}
                                handleChange={handleChange}
                                hasPermission={hasPermission}
                            />
                            <FormTextField
                                name="password"
                                label="Neues Passwort"
                                password
                                object={userUpdate}
                                handleChange={handleChange}
                                hasPermission={hasPermission}
                                minLength={12}
                                maxLength={36}
                            />
                            <FormTextField
                                name="password_confirm"
                                label="Neues Passwort bestätigen"
                                password
                                object={userUpdate}
                                handleChange={handleChange}
                                hasPermission={hasPermission}
                                minLength={12}
                                maxLength={36}
                            />
                        </Stack>
                    </Stack>
                </FormBox>
            }
            dialogActions={
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    width={"100%"}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                    >
                        <Button
                            //disabled={!isUpdated}
                            onClick={handleReset}
                            sx={{
                                textTransform: "none",
                                color: '#000',
                                border: "1px solid transparent",
                                borderRadius: 1,
                                "&:hover": {
                                    color: '#fff',
                                    background: 'rgba(219, 46, 51, 0.9)',
                                }
                            }}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            disabled={disablePasswordChange()}
                            onClick={handleUpdate}
                            sx={{
                                textTransform: "none",
                                background: '#168d1a',
                                color: '#fff',
                                border: "1px solid transparent",
                                borderRadius: 1,
                                "&:hover": {
                                    color: '#fff',
                                    background: '#106f13',
                                }
                            }}
                        >
                            Speichern
                        </Button>
                    </Stack>
                </Stack>
            }
        />

    );
}

UserPasswordForm.propTypes = {
    user: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};
