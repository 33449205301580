import * as React from "react";
import { Link } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Stack } from "@mui/material";
import FormBox from "../../../../components/FormBox";

import { useApp } from "../../../../context/App";


function User({ 
    username = null, 
    first_name = "",
    last_name = ""
}) {
    const targetRoute = `/admin/user/${username}`;
    return (
        <Chip
            size="small"
            Typographyvariant="outlined"
            style={{ textTransform: "initial", fontWeight: 500, margin: 3 }}
            label={
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <Link to={targetRoute} target="_blank" style={{ color: "inherit", textDecoration: "none" }}>
                        {first_name} {last_name}
                    </Link>
                    <Link to={targetRoute} target="_blank" style={{ color: "inherit" }}>
                        <OpenInNewIcon color="#fff" sx={{ marginTop: .5, width: ".8em", height: ".8em" }} />
                    </Link>
                </Stack>
            }
        />
    );
}

function PlaybackPlayer({ id = null, name = "" }) {
    const targetRoute = `/playback/player/${id}`;
    return (
        <Chip
            size="small"
            Typographyvariant="outlined"
            style={{ textTransform: "initial", fontWeight: 500, margin: 3 }}
            label={
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <Link to={targetRoute} target="_blank" style={{ color: "inherit", textDecoration: "none" }}>
                        {name}
                    </Link>
                    <Link to={targetRoute} target="_blank" style={{ color: "inherit" }}>
                        <OpenInNewIcon color="#fff" sx={{ marginTop: .5, width: ".8em", height: ".8em" }} />
                    </Link>
                </Stack>
            }
        />
    );
}

function PlaybackUser({ id = null, name = "" }) {
    const targetRoute = `/playback/user/${id}`;
    return (
        <Chip
            size="small"
            Typographyvariant="outlined"
            style={{ textTransform: "initial", fontWeight: 500, margin: 3 }}
            label={
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <Link to={targetRoute} target="_blank" style={{ color: "inherit", textDecoration: "none" }}>
                        {name}
                    </Link>
                    <Link to={targetRoute} target="_blank" style={{ color: "inherit" }}>
                        <OpenInNewIcon color="#fff" sx={{ marginTop: .5, width: ".8em", height: ".8em" }} />
                    </Link>
                </Stack>
            }
        />
    );
}

function Linklist({ player, playback_user, user }) {
    return (
        <>
            <FormBox title="Zugänge">
                {user.map((u) => (
                    <User
                        key={u.id}
                        username={u.username}
                        first_name={u.first_name}
                        last_name={u.last_name}
                    />
                ))}
            </FormBox>
            <FormBox title="Player">
                {player.map((p) => (
                    <PlaybackPlayer key={p.id} id={p.id} name={p.name} />
                ))}
            </FormBox>
            <FormBox title="Direkt-Zugänge">
                {playback_user.map((u) => (
                    <PlaybackUser key={u.id} id={u.id} name={u.name} />
                ))}
            </FormBox>
        </>
    );
};

export default function AdminACLSidebarLinks({ link }) {
    const { client } = useApp();
    const loading = !link;

    return (
        <span>
            {loading ? (
                <CircularProgress color="inherit" size={20} />
            ) : (
                <Linklist user={link.user} player={link.player} playback_user={link.playback_user} />
            )}
        </span>
    );
};
