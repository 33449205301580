import * as React from 'react';
import { Button, Popover } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';


export default function FormAddButton({
    children = null,
    hasPermission = false,
    anchorEl = null,
    arrowRef = null,
    open = false,
    handleClick = () => { },
    handleClose = () => { },
}) {

    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    return (
        <div>
            <Button
                id="form-add-button"
                aria-controls={open ? 'form-add-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                color="primary"
                size="small"
                disabled={!hasPermission}
                sx={{
                    height: 40,
                    boxShadow: 1,
                    borderRadius: 1,
                    background: '#fff',
                    border: '1px solid rgba(0,0,0,.2)',
                    fontSize: '1em',
                    padding: "0px 10px",
                    color: '#0085E9',
                    "&:hover": {
                        color: '#fff',
                        background: '#0085E9',
                    }
                }}
            >
                <AddCircleIcon style={{ marginRight: 5 }} />
                Neu
            </Button>
            <Popover
                id="form-add-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            boxShadow: 1,
                            borderRadius: 1,
                            background: "#fff",
                            border: "1px solid rgba(0,0,0,.2)",
                            overflow: "visible",
                            mt: 1.5,
                            "&::before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                                border: "1px solid rgba(0,0,0,.25)",
                                borderWidth: "1px 0px 0px 1px",
                            },
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                        },
                    },
                }}
            >
                {children}
            </Popover>
        </div>
    );
}