import React from 'react';

import FormTextField from '../../../../components/FormTextField';

export default function PlaybackUserSidebarIdForm({ 
    user = {},
}) {

    return (
        <>
            <FormTextField
                name="id"
                label="ID"
                object={{id: user.id}}
            />
        </>
    );
}