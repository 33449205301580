import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import Page from "../../components/Page";
import SearchField from "../../components/SearchField";

import PlayerInventory from "./PlaybackPlayer";
import PlaybackPlayerSidebar from "./PlaybackPlayer/PlaybackPlayerSidebar";
import PlaybackPlayerAddButton from "./PlaybackPlayer/PlaybackPlayerAddButton";

import { useApp } from "../../context/App";

function Player(props) {
    const { rootPath } = props;
    const { client, hasPermission, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PERMISSION CHECK
    const playbackPlayerAdminRoles = ["admin", "player:admin"];
    const playbackPlayerEditorRoles = playbackPlayerAdminRoles.concat([
        "editor",
        "player:editor",
    ]);

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        navigate(rootPath);
    };

    // PLAYER VALUES
    const navigate = useNavigate();
    const { player_id } = useParams();
    const [players, setPlayers] = React.useState(null);
    const [player, setPlayer] = React.useState(null);

    // PLAYER OBJECT
    const [playerConfig, setPlayerConfig] = React.useState(false);
    const getPlayerConfig = async () => {
        if (!playerConfig) {
            const data = client.PlayerConfig();
            setPlayerConfig(data);
        }
        return playerConfig;
    };

	const getPlayers = async () => {
		const data = await client.GetCustomerPlayer({});
		if (data) {
			setPlayers(data);
		}
	};

	const createPlayer = async ({
		player = null,
	}) => {
		if (!player) return;
		let data = null;
		data = await client.PostCustomerPlayer({
			player: player
		});
		if (data) {
			setPlayer(data);
			setPlayers([...players, data]);
            navigate(`${rootPath}/${data.id}`);
		}
	};

	const updatePlayer = async ({
		player_id = null,
		player = null,
	}) => {
		if (!player_id || !player) return;
		let data = null;
		data = await client.PutCustomerPlayerId({
            player_id: player_id, player: player
        });
		if (data) {
			setPlayer(data);
			setPlayers(players.map((item) => (item.id === player_id ? data : item)));
		}
	};

	const deletePlayer = async ({
		player_id = null,
	}) => {
		if (!player_id) return;
		await client.DeleteCustomerPlayerId({
			player_id: player_id
		});
		setPlayers(players.filter(item => item.id !== player_id));
		navigate(rootPath);
	};

    React.useEffect(() => {
        (async () => {
            setSelectedPage("playback_player");
            await getPlayerConfig();
            await getPlayers();
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
        // eslint-disable-next-line
    }, [setSelectedPage, client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    React.useEffect(() => {
        if (players && player_id) {
            const data = players.find((player) => player.id === player_id);
            if (!data) {
                navigate(rootPath);
            } else {
                setPlayer(data);
                setOpenSidebar(true);
            }
        } else {
            setPlayer(null);
            setOpenSidebar(false);
        }
    }, [players, player_id]);

    const drawerWidth = 480;
    const [searchText, setSearchText] = React.useState("");

    const breadcrumbs = () => {
		if (player) {
            return [
                {title: 'Wiedergabe', link: '#'},
                {title: 'Player', link: rootPath},
                {title: `${player.name}`, link: `${rootPath}/${player.id}`},
            ]
        } else {
            return [
                {title: 'Wiedergabe', link: '#'},
                {title: 'Player', link: rootPath}
            ]
        }
	}

    return (
        <Page
            pageTitel="Player"
            breadcrumbs={breadcrumbs ? breadcrumbs() : []}
            drawerWidth={drawerWidth}
            headerContentRight={
                <>
                    <SearchField
                        outlined
                        searchText={searchText}
                        setSearchText={setSearchText} 
                    />
                    <PlaybackPlayerAddButton
                        createPlayer={createPlayer}
                        hasPermission={hasPermission(playbackPlayerAdminRoles)}
                    />
                </>
            }
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            sidebarContent={
                player && (
                    <PlaybackPlayerSidebar
                        key={player.id}
                        player={player}
                        playerConfig={playerConfig}
                        updatePlayer={updatePlayer}
                        deletePlayer={deletePlayer}
                        hasPermission={hasPermission(playbackPlayerEditorRoles)}
                        drawerWidth={drawerWidth}
                        handleSidebarToggle={handleSidebarToggle}
                    />
                )
            }
        >
            {players && (
                <PlayerInventory
                    rootPath={rootPath}
                    players={players}
                    player_id={player_id}
                    searchText={searchText}
                    navigate={navigate}
                />
            )}
        </Page>
    );
}

Player.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

Player.defaultProps = {
    openMenu: false,
};

export default Player;
