import { useState } from "react";

export function removeEmptyStrings(data) {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, value === "" ? null : value])
  );
}

export const generatePassword = (length = 15) => {
  if (length < 15) {
    length = 15;
  }
  const lower = "abcdefghijklmnopqrstuvwxyz";
  const upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const special = "!@#$%^&*()-_=+[]{}|;:,.<>?";
  const passwordArray = [
    lower[Math.floor(Math.random() * lower.length)],
    upper[Math.floor(Math.random() * upper.length)],
    numbers[Math.floor(Math.random() * numbers.length)],
    special[Math.floor(Math.random() * special.length)]
  ];
  const allChars = lower + upper + numbers + special;
  for (let i = passwordArray.length; i < length; i++) {
    passwordArray.push(allChars[Math.floor(Math.random() * allChars.length)]);
  }
  for (let i = passwordArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [passwordArray[i], passwordArray[j]] = [passwordArray[j], passwordArray[i]];
  }
  return passwordArray.join("");
};

export const validPasswordStrength = (value) => {
  // Das Passwort muss zwischen 15 und 35 Zeichen lang sein, 
  // mindestens einen Großbuchstaben, einen Kleinbuchstaben, 
  // eine Zahl und ein Sonderzeichen enthalten."
  let score = 0;
  if (!value) return '';
  if (value.length >= 15) score += 1;
  if (/[a-z]/.test(value)) score += 1;
  if (/[A-Z]/.test(value)) score += 1;
  if (/\d/.test(value)) score += 1;
  if (/[^A-Za-z0-9]/.test(value)) score += 1;
  if (score < 4) return false;
  return true;
}

export const validHttpUrl = (value) => {
  if (value === "") return true;
  const regex = /^https?:\/\/\S+$/;
  return regex.test(value);
};

export const validDomain = (value) => {
  if (value === "") return true;
  const regex = /^(?:\*\.)?(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
  return regex.test(value);
};

export const validHexColor = (value) => {
  if (value === "") return true;
  const regex = /^#(?:[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return regex.test(value);
};

export const validEmail = (value) => {
  if (value === "") return true;
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(value);
};

export const validPhone = (value) => {
  if (value === "") return true;
  const regex = /^\d{10,15}$/;
  return regex.test(value);
};

export const validTimeFormat = (value) => {
  const regex = /^(0\d|1\d|2[0-3]):[0-5]\d$/;
  return regex.test(value);
};

export function useFormSection({
  formDefault = {},
}) {
  const [isUpdated, setIsUpdated] = useState(false);
  const [formUpdate, setFormUpdate] = useState(formDefault);

  function handleChange(e) {
    const { type, name, value } = e.target;
    setFormUpdate({
      ...formUpdate,
      [name]: type === "checkbox" ? !formUpdate[name] : value
    });
    setIsUpdated(true);
  }

  function handleReset() {
    setFormUpdate((prev) => {
      return formDefault;
    });
    setIsUpdated(false);
  }

  return {
    isUpdated,
    setIsUpdated,
    formUpdate,
    setFormUpdate,
    handleChange,
    handleReset
  };
}