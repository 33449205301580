import * as React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";


function DeleteDialog({
    acl = {},
    link = {},
    deleteAcl = () => {},
    openDeleteDialog = false,
    handleCloseDeleteDialog = () => {},
}) {
    const isUnused = link && (link.user.length === 0 && link.player.length === 0 && link.playback_user.length === 0);

    if (isUnused) {
        return (
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Zugriffskontrollliste (ACL) löschen"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Möchten die Zugriffskontrollliste (ACL) <strong>{acl.name}</strong> wirklich
                        löschen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="default"
                        color="warning"
                        onClick={handleCloseDeleteDialog}
                    >
                        Nein
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => deleteAcl({acl_id: acl.id})}
                        autoFocus
                    >
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return (
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Zugriffskontrollliste (ACL) löschen"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Die Zugriffskontrollliste (ACL) <strong>{acl.name}</strong> kann nicht gelöscht
                        werden, weil sie noch in Verwendung ist.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCloseDeleteDialog}
                        autoFocus
                    >
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default function AdminACLSidebarMenu({ 
    acl = null, 
    link = null,
    hasPermission = false,
    deleteAcl = () => {},
}) {
    const [anchorEl, setAnchorEl] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Delete Dialog
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const handleClickOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    if (!acl) {
        return;
    }

    return (
        <>
            <IconButton
                color="primary"
                size="small"
                id="message-menu-button"
                aria-controls={open ? "message-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="message-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "message-menu-button",
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuList>
                    <MenuItem
                        onClick={handleClickOpenDeleteDialog}
                        disabled={!hasPermission}
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Löschen</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
            <DeleteDialog
                acl={acl}
                link={link}
                deleteAcl={deleteAcl}
                openDeleteDialog={openDeleteDialog}
                handleCloseDeleteDialog={handleCloseDeleteDialog}
            />
        </>
    );
};