import * as React from "react";

import GppGoodIcon from "@mui/icons-material/GppGood";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Logo from "../images/livespotting.svg";

import TwoFactorToken from "../components/TwoFactorToken";

import { useApp } from "../context/App";

function ServiceIcon(props) {
    return <img src={Logo} alt="livespotting.com Icon" {...props} />;
}

function LoginTwoFactor() {
    const { client } = useApp();

    const [otp_token, setOtpToken] = React.useState("");

    const [failCount, setFailCount] = React.useState(0);
    const handleLoginFailed = async () => {
        if (failCount >= 2) {
            await client.logout();
        } else {
            setFailCount((prev) => (prev + 1));
        }
    };

    const sendTwoFactor = async () => {
        if (otp_token.length !== 6) {
            return;
        } else {
            try {
                const data = await client.loginTwoFactorAuth({
                    otp_token: otp_token,
                });
                if (!data) {
                    await handleLoginFailed();
                }
            } catch (error) {
                await handleLoginFailed();
            }
        }
    };

    const handleTwoFactorAuthRefresh = async () => {
        await client.refreshTwoFactorAuthToken();
    };

    const handleAbort = async () => {
        await client.logout();
    };

    React.useEffect(() => {
        if (otp_token && otp_token.length === 6) {
            sendTwoFactor();
        }
        // eslint-disable-next-line
    }, [otp_token]);

    return (
        <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            height={"100vh"}
            width={"100%"}
            margin={"-8px -8px -8px 0px"}
        >
            <Stack></Stack>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-left"
                spacing={1}
            >
                <ServiceIcon
                    style={{
                        width: 40,
                        height: 40,
                        position: "fixed",
                        left: "1.1em",
                        top: "1.3em",
                    }}
                />
                <Stack
                    direction="column"
                    spacing={1}
                    justifyContent="center"
                    alignItems="left"
                    maxWidth={340}
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                        marginTop={-5}
                        marginBottom={1}
                    >
                        <GppGoodIcon
                            size="large"
                            style={{
                                width: "1.5em",
                                height: "1.5em",
                                marginRight: "0px",
                                marginTop: "-1px",
                                color: "rgb(40, 172, 46)",
                            }}
                        />
                        <Typography
                            fontSize={24}
                            fontWeight={400}
                            marginLeft={0.3}
                            
                        >
                            Login Verifizierung
                        </Typography>
                    </Stack>
                    <Typography
                        fontSize={14}
                        fontWeight={400}
                        textAlign={"center"}
                        paddingTop={1}
                        paddingBottom={1}
                    >
                        Bitte bestätigen Sie Ihre Identität mit dem Verifizierungscode, den Sie per E-Mail erhalten haben.
                    </Typography>
                    <TwoFactorToken
                        otp_token={otp_token}
                        setOtpToken={setOtpToken}
                    />
                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        marginTop={-5}
                        marginBottom={1}
                    >
                        <Typography
                            fontSize={14}
                            fontWeight={400}
                            paddingTop={-1}
                            textAlign={"center"}
                            onClick={handleTwoFactorAuthRefresh}
                            onMouseEnter={(e) => e.target.style.textDecoration  = "underline"}
                            onMouseLeave={(e) => e.target.style.textDecoration  = "none"}
                            style={{ 
                                cursor: "pointer", 
                        }}
                        >
                            Neuen Code anfordern
                        </Typography>
                        <Typography
                            fontSize={14}
                            fontWeight={400}
                            paddingTop={-1}
                            textAlign={"center"}
                            onClick={handleAbort}
                            onMouseEnter={(e) => e.target.style.textDecoration  = "underline"}
                            onMouseLeave={(e) => e.target.style.textDecoration  = "none"}
                            style={{ 
                                cursor: "pointer", 
                        }}
                        >
                            Abbrechen
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack></Stack>
        </Stack>
    );
}

LoginTwoFactor.propTypes = {};

LoginTwoFactor.defaultProps = {};

export default LoginTwoFactor;
