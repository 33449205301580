import * as React from 'react';
import { Button, Checkbox, FormGroup, FormControlLabel, Menu, Stack } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import FormAddButton from '../../../components/FormAddButton';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormTextField from '../../../components/FormTextField';
import { removeEmptyStrings, useFormSection, generatePassword, validPasswordStrength } from '../../../components/misc/useForm';
import ACLSelect from '../../../components/ACLSelectV2';

export default function PlaybackUserAddButton({
    createUser = () => {},
    hasPermission = false, 
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const userCreateDefault = {
        name: "",
        description: "",
        acl_id: null,
    };

    const { 
        isUpdated, 
        setIsUpdated,
        formUpdate: userCreate,
        setFormUpdate: setUserCreate,
        handleChange,
    } = useFormSection({
        formDefault: userCreateDefault,
    });

    function handleReset() {
        setUserCreate((prev) => {
            return {
                ...prev,
                ...userCreateDefault,
            };
        });
        setIsUpdated(false);
        handleClose();
    }

    async function handleCreate() {    
        createUser({
            user: removeEmptyStrings(userCreate),
        });
        handleReset();
        handleClose();
    }

    const validForm = (
        userCreate.name.length > 0 && 
        userCreate.description.length > 0
    );

    return (
        <FormAddButton
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            handleClick={handleClick}
            hasPermission={hasPermission}
        >
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    padding: 4,
                    width: 300,
                }}
            >
                <FormTextField
                    name="name"
                    label="Name"
                    object={userCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={100}
                />
                <FormTextField
                    name="description"
                    label="Beschreibung"
                    object={userCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={255}
                />
                <ACLSelect
                    name="acl_id"
                    label="Zugriffskontrollliste (ACL)"
                    placeholder="Keine Einschränkung"
                    object={userCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                />
                {hasPermission && isUpdated && (
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            justifyContent: "flex-end",
                            alignItems: "center",
                            width: "100%",
                            paddingTop: 1,
                        }}
                    >
                        <FormButtonGroup 
                            handleReset={handleReset}
                            handleUpdate={handleCreate}
                            isValid={validForm}
                            spaceBetween
                        />
                    </Stack>
                )}
            </Stack>
        </FormAddButton>
    );
}