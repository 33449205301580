import * as React from "react";

import "moment-timezone";
import moment from "moment";

import Divider from "@mui/material/Divider";
import Moment from "react-moment";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { DefaultCopyField } from "../../../../components/CopyButton";
import PageSidebarHeader from "../../../../components/PageSidebarHeader";
import SidebarAccordion from "../../../../components/SidebarAccordion";
import FormBox from "../../../../components/FormBox";
import VideoJS from "../../../../components/VideoJS";

import PlaybackUserSidebarAccessControlForm from "./PlaybackUserSidebarAccessControlForm";
import PlaybackUserSidebarMetadataForm from "./PlaybackUserSidebarMetadataForm";
import PlaybackUserSidebarIdForm from "./PlaybackUserSidebarIdForm";
import PlaybackUserSidebarSubMenu from "./PlaybackUserSidebarSubMenu";
import PlaybackUserSidebarCameraSelect from "./PlaybackUserSidebarCameraSelect";

import { useApp } from "../../../../context/App";

function PlaybackUserSidebar({
    user = null,
    updateUser = () => {},
    deleteUser = () => {},
    playerConfig = {},
    drawerWidth = 320,
    handleSidebarToggle,
    hasPermission = false,
}) {
    const { client } = useApp();
    const customerId = client.CustomerId();

    // Access > PlaybackUserSidebarCameraSelect
    const [cameras, setCameras] = React.useState(null);
    const [selectedCamera, setSelectedCamera] = React.useState(null);

    const getCameras = async () => {
        const data = await client.GetCustomerCamera({
            acl_id: user.acl_id,
            limit: 0,
        });
        setCameras(data);
        setSelectedCamera(data[0]);
    };

    React.useEffect(() => {
        getCameras();
        // eslint-disable-next-line
    }, [user]);

    // VIDEOJS
	const videoJsOptions = () => {
        if (!selectedCamera) {
            return null;
        }
        return {
            autoplay: false,
            controls: true,
            responsive: true,
            fluid: true,
            poster: playerConfig.url + selectedCamera?.id + '/live/main_240.jpg',
            playsinline: true,
            muted: true,
            // html5: {
            //     hls: {
            //         overrideNative: true,
            //         limitRenditionByPlayerDimensions: true,
            //         useDevicePixelRatio: true
            //     },
            //     nativeAudioTracks: false,
            //     nativeVideoTracks: false,
            //     useBandwidthFromLocalStorage: true
            // },
            sources: [
                {
                    src: playerConfig.url + selectedCamera?.id + '/live/main.m3u8?token=' + playerConfig.token,
                    type: 'application/vnd.apple.mpegurl',
                },
            ],
        };
	};

    return (
        <Stack 
            direction="column"
            spacing={0}
            sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
                paddingBottom: 4,
            }}
        >
            <PageSidebarHeader
                title={user.name}
                share={true}
                handleToggle={handleSidebarToggle}
                submenu={
                    <PlaybackUserSidebarSubMenu 
                        user={user} 
                        updateUser={updateUser}
                        deleteUser={deleteUser}
                        hasPermission={hasPermission}
                    />
                }
                player={
                    selectedCamera && <VideoJS key={user.id} {...videoJsOptions()} />
                }
            />
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    width: "100%",
                    paddingBottom: 2,
                    paddingLeft: 2.5,
                    paddingRight: 2.5,
                }}
            >
                <FormBox>
                    <PlaybackUserSidebarIdForm
                        user={user}
                    />
                </FormBox>
            </Stack>
            <Divider sx={{marginTop: 0, marginBottom: 0}}/>
            <SidebarAccordion
                first
                expanded
                title="Stream-Links"
                content={
                    <>
                        {cameras && (
                            <PlaybackUserSidebarCameraSelect
                                cameras={cameras}
                                selectedCamera={selectedCamera}
                                setSelectedCamera={setSelectedCamera}
                            />
                        )}
                        {selectedCamera && user.protocol_allowlist.includes("hls") && (
                            <React.Fragment>
                                <Typography marginTop={2.5} marginBottom={1.5} fontSize={16} fontWeight={500}>
                                    HLS Stream (HTTP Livestreaming)
                                </Typography>
                                <Stack
                                    style={{
                                        padding: "15px 15px 15px 15px",
                                        marginBottom: 5,
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                    }}
                                    spacing={2}
                                >
                                    <DefaultCopyField
                                        value={`https://${user.id}:${user.password}@wettercom-c1.livespotting.com/memfs/${customerId}/${selectedCamera.id}/live/main.m3u8`}
                                        multiline
                                        maxRows={3}
                                    />
                                </Stack>
                            </React.Fragment>
                        )}
                        {selectedCamera && user.protocol_allowlist.includes("rtmp") && (
                            <React.Fragment>
                                <Typography marginTop={2.5} marginBottom={1.5} fontSize={16} fontWeight={500}>
                                    RTMP Stream
                                </Typography>
                                <Stack
                                    style={{
                                        padding: "15px 15px 15px 15px",
                                        marginBottom: 5,
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                    }}
                                    spacing={2}
                                >
                                    <DefaultCopyField
                                        value={`rtmp://wettercom-c1.livespotting.com/${customerId}/${selectedCamera.id}.stream/${user.id}:${user.password}`}
                                        multiline
                                        maxRows={3}
                                    />
                                </Stack>
                            </React.Fragment>
                        )}
                        {selectedCamera && user.protocol_allowlist.includes("srt") && (
                            <React.Fragment>
                                <Typography marginTop={2.5} marginBottom={1.5} fontSize={16} fontWeight={500}>
                                    SRT Stream
                                </Typography>
                                <Stack
                                    style={{
                                        padding: "15px 15px 15px 15px",
                                        marginBottom: 5,
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                    }}
                                    spacing={2}
                                >
                                    <DefaultCopyField
                                        value={`srt://wettercom-c1.livespotting.com:6000/?mode=caller&transtype=live&streamid=${customerId}/${selectedCamera.id}.stream,mode:request,token:${user.id}:${user.password}`}
                                        multiline
                                        maxRows={3}
                                    />
                                </Stack>
                            </React.Fragment>
                        )}
                    </>
                }
            />
            <SidebarAccordion
                title="Metadaten"
                content={
                    <FormBox>
                        <PlaybackUserSidebarMetadataForm
                            user={user}
                            updateUser={updateUser}
                            hasPermission={hasPermission}
                        />
                    </FormBox>
                }
            />
            <SidebarAccordion
                title="Zugriffskontrolle"
                content={
                    <FormBox>
                        <PlaybackUserSidebarAccessControlForm
                            user={user}
                            updateUser={updateUser}
                            hasPermission={hasPermission}
                        />
                    </FormBox>
                }
            />
            <SidebarAccordion
                last
                title="Protokoll"
                content={
                    <>
                        <FormBox>
                            <Typography fontSize={14}>
                                <strong>Erstellt:</strong>{" "}
                                <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                    {moment(user.created_at).tz(
                                        moment.tz.guess(),
                                    )}
                                </Moment>
                            </Typography>
                            {user.updated_at && (
                                <Typography fontSize={14}>
                                    <strong>Aktualisiert:</strong>{" "}
                                    <Moment
                                        format="DD.MM.YYYY HH:mm:ss"
                                        locale="de"
                                    >
                                        {moment(user.updated_at).tz(
                                            moment.tz.guess(),
                                        )}
                                    </Moment>
                                </Typography>
                            )}
                        </FormBox>
                    </>
                }
            />
        </Stack>
    );
}

PlaybackUserSidebar.propTypes = {
    drawerWidth: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
};

PlaybackUserSidebar.defaultProps = {};

export default PlaybackUserSidebar;
