export default function PlaybackPlayerSidebarEmbedCodes({
    type = "native",
    customerId = "",
    playerId = "",
    cameraId = "",
}) {
    if (type === "native") {
        return (
`<link href="https://s3.livespotting.com/player/static/player.min.css" rel="stylesheet"/>
<script src="https://s3.livespotting.com/player/static/player.min.js"></script>
<video-js
    id="cmPlayer1"
    data-player="default"
    data-embed="default"
    controls
></video-js>
<script>
    const player = videojs('cmPlayer1');
    player.ready(function () {
        this.cmPlayerInit({
        customerId: '${customerId}',
        playerId: '${playerId}',
        cameraId: '${cameraId}',
        });
    });
</script>`
        );
    } else if (type === "iframe") {
        return (
`<iframe src="${"https://cm.livespotting.com/v1/player/" + customerId + "/" + playerId + "/" + cameraId}" width="640" height="360" frameborder="no" scrolling="no" allowfullscreen="true"></iframe>`
        );
    }
}