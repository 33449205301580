import * as React from "react";
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from "prop-types";

import Page from "../../components/Page";
import SearchField from "../../components/SearchField";

import AdminACL from "./AdminACL";
import AdminACLSidebar from "./AdminACL/AdminACLSidebar";
import AdminACLAddButton from "./AdminACL/AdminACLAddButton";

import { useApp } from "../../context/App";

function Admin(props) {
    const { rootPath } = props;
    const { client, hasPermission, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PERMISSION CHECK
    const adminRoles = ["admin"];

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        if (!openSidebar === false) {
            navigate(rootPath);
        }
    };

    const navigate = useNavigate();
    const { acl_id } = useParams();
    const [acls, setACLs] = React.useState(null);
    const [acl, setACL] = React.useState(null);

    const getAcls = async () => {
		let data = null;
		data = await client.GetCustomerACL();
		if (data) {
            setACLs(data);
		}
	};

    const createAcl = async ({
		acl = null,
	}) => {
		if (!acl) return;
		let data = null;
		if (acl) {
			data = await client.PostCustomerACL({
				acl: acl
			});
		}
		if (data) {
			setACL(data);
            setACLs([...acls, data]);
		}
	};

    const updateAcl = async ({
		acl_id = null,
		acl = null,
        acl_logo_image = null,
	}) => {
		if (!acl_id) return;
		let data = null;
		if (acl) {
			data = await client.PutCustomerACLId({
				acl_id: acl_id, acl: acl
			});
		}
        if (acl_logo_image) {
			data = await client.PutCustomerACLIdLogoImage({
				acl_id: acl_id, image: acl_logo_image
			});
		}
		if (data) {
			setACL(data);
			setACLs(acls.map((item) => (item.id === acl_id ? data : item)));
		}
	};

    const deleteAcl = async ({
		acl_id = null,
	}) => {
		if (!acl_id) return;
		await client.DeleteCustomerACLId({
			acl_id: acl_id
		});
		setACLs(acls.filter(item => item.id !== acl_id));
		navigate(rootPath);
	};

    React.useEffect(() => {
        (async () => {
            setSelectedPage("admin_acl");
            await getAcls();
            setReady(true); 
            return () => {
                setReady(false);
            };
        })();
    }, [setSelectedPage, client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    React.useEffect(() => {
        if (acls && acl_id) {
            const data = acls.find((acl) => acl.id === acl_id);
            if (!data) {
                navigate(rootPath);
            } else {
                setACL(data);
                setOpenSidebar(true);
            }
            setACL(data);
            setOpenSidebar(true);
        } else {
            setACL(null);
            setOpenSidebar(false);
        }
    }, [acls, acl_id]);

    const drawerWidth = 480;
    const [searchText, setSearchText] = React.useState("");

    const breadcrumbs = () => {
		if (acl) {
            return [
                {title: 'Admin', link: '#'},
                {title: 'ACL', link: rootPath},
                {title: acl.name, link: `${rootPath}/${acl.id}`},
            ]
        } else { 
            return [
                {title: 'Admin', link: '#'},
                {title: 'ACL', link: rootPath}
            ]
        };
	}

    return (
        <Page
            drawerWidth={drawerWidth}
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            pageTitel="ACL"
			breadcrumbs={breadcrumbs ? breadcrumbs() : []}
            headerContentRight={
                <>
                    <SearchField 
                        outlined
                        searchText={searchText}
                        setSearchText={setSearchText} 
                    />
                    <AdminACLAddButton
                        createAcl={createAcl}
                        hasPermission={hasPermission(adminRoles)}
                    />
                </>
            }
            sidebarContent={
                acl && (
                    <AdminACLSidebar
                        key={acl.id}
                        acl={acl}
                        updateAcl={updateAcl}
                        deleteAcl={deleteAcl}
                        hasPermission={hasPermission(adminRoles)}
                        drawerWidth={drawerWidth}
                        handleSidebarToggle={handleSidebarToggle}
                    />
                )
            }
        >
            {acls && (
                <AdminACL 
                    rootPath={rootPath}
                    acls={acls}
                    acl_id={acl_id}
                    searchText={searchText}
                    navigate={navigate}
                />
            )}
        </Page>
    );
}

Admin.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

export default Admin;
