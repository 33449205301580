import React from 'react';
import { Checkbox, FormGroup, FormControlLabel, Stack } from '@mui/material';

import ImageUploadField from '../../../components/ImageUploadField';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormTextField from '../../../components/FormTextField';
import { removeEmptyStrings, useFormSection,  } from '../../../components/misc/useForm';


export default function PlayoutTemplateForm({
    playout = {},
    updatePlayout = () => { },
    hasPermission = false,
}) {
    const playoutDefault = {
        template_name: playout.template_name || 'default',
        template_settings: playout.template_settings || "",
        background_image: playout.background_image || "",
        display_safearea: playout.display_safearea || false,
    };
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: playoutUpdate, 
        setFormUpdate: setPlayoutUpdate,
        handleChange,
    } = useFormSection({
        formDefault: playoutDefault,
    });
    const [backgroundImage, setBackgroundImage] = React.useState(null);

    React.useEffect(() => {
        if (backgroundImage && backgroundImage.data !== playoutUpdate.background_image) {
            setIsUpdated(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backgroundImage]);

    function handleReset() {
        setPlayoutUpdate((prev) => {
            return playoutDefault;
        });
        setBackgroundImage((prev) => {
            return null
        });
        setIsUpdated(false);
    }

    async function handleUpdate() {
        updatePlayout({
            playout_id: playout.id,
            playout: removeEmptyStrings(playoutUpdate),
            playout_background_image: backgroundImage !== playoutDefault.background_image ? backgroundImage : null,
        });
        setIsUpdated(false);
    }

    const template_names = [
        {
            value: 'default',
            label: 'Standard',
        },
    ];

    return (
        <>
            <FormTextField
                name="template_name"
                label="Template"
                object={playoutUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                select
                selectOptions={template_names}
            />
            <FormTextField
                name="template_settings"
                label="Template Einstellungen"
                object={playoutUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                multiline
                inRows={4}
                maxRows={12}
            />
            <Stack direction="row" spacing={2} alignItems="center" width={"100%"}>
                <ImageUploadField
                    id="background_image"
                    uploadedFile={backgroundImage?.data || playoutUpdate.background_image}
                    setUploadedFile={setBackgroundImage}
                    width={72}
                    height={42}
                    readOnly={!hasPermission}
                />
                <FormTextField
                    name="background_image"
                    label="Hintergrundbild"
                    object={playoutUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                />
            </Stack>
            <FormGroup>
                <FormControlLabel
                    name="display_safearea"
                    label="Safearea einblenden"
                    onChange={handleChange}
                    readOnly={!hasPermission}
                    control={
                        <Checkbox
                            checked={playoutUpdate.display_safearea ? true : false}
                            color={"blue"}
                        />
                    }
                />
            </FormGroup>
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <FormButtonGroup
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}