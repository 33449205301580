import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function PlaybackPlayerSidebarCameraSelect({
    playlist = [],
    selectedCamera = null,
    setSelectedCamera = () => {},
}) {
    const [options, setOptions] = React.useState(null);

    React.useEffect(() => {
        if (!playlist) return;
        const newData = playlist
            .map(({ camera_id, meta, source, poster }) => ({
                camera_id: camera_id,
                name: meta.name,
                city: meta.city,
                country: meta.country,
                source: source,
                poster: poster,
            }))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .filter((obj, index, array) => {
                const currentIndex = array.findIndex(
                    (item) => item.camera_id === obj.camera_id,
                );
                return currentIndex === index;
            })
            .sort((a, b) => -(a.country || '').localeCompare(b.country || ''));
        setOptions(newData);
    }, [playlist]);

    const selectedCameraValue = (camera) => {
        if (!camera.meta) {
            return camera;
        }
        const { camera_id, meta, source, poster } = camera;
        return {
            camera_id: camera_id,
            name: meta.name,
            city: meta.city,
            country: meta.country,
            source: source,
            poster: poster,
        };
    };

    return (
        <Autocomplete
            value={selectedCamera ? selectedCameraValue(selectedCamera) : null}
            size="small"
            id="player-camera-select"
            options={options ? options : []}
            groupBy={(option) => option.country}
            getOptionLabel={(option) => `${option.name} (${option.city})`}
            onChange={(event, newValue) => {
                setSelectedCamera(newValue);
            }}
            isOptionEqualToValue={(option, value) =>
                option.camera_id === value.camera_id
            }
            fullWidth
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="Kamera Auswahl"
                />
            )}
        />
    );
}
