import React from 'react';
import { Stack } from '@mui/material';

import ImageUploadField from '../../../../components/ImageUploadField';
import FormButtonGroup from '../../../../components/FormButtonGroup';
import FormTextField from '../../../../components/FormTextField';
import { removeEmptyStrings, useFormSection } from '../../../../components/misc/useForm';


export default function AdminACLSidebarMetaForm({
    acl = {},
    updateAcl = () => {},
    hasPermission = false, 
}) {

    const aclDefault = {
        name: acl.name || "",
        description: acl.description || "",
        logo_url: acl.logo_url || null,
    };

    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: aclUpdate, 
        setFormUpdate: setActUpdate,
        handleChange,
    } = useFormSection({
        formDefault: aclDefault,
    });

    const [logoImage, setLogoImage] = React.useState(null);
    React.useEffect(() => {
        if (logoImage && logoImage.data !== aclUpdate.logo_url) {
            setIsUpdated(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoImage]);

    function handleReset() {
        setActUpdate((prev) => {
            return {
                ...prev,
                ...aclDefault,
            };
        });
        setLogoImage((prev) => {
            return null
        });
        setIsUpdated(false);
    }

    async function handleUpdate() {    
        updateAcl({
            acl_id: acl.id, 
            acl: removeEmptyStrings(aclUpdate),
            acl_logo_image: logoImage !== aclUpdate.logo_url ? logoImage : null,
        });
        setIsUpdated(false);
    }

    const formIsValid = aclUpdate.name.length > 0 && aclUpdate.description.length > 0;

    return (
        <>
            <FormTextField
                name="name"
                label="Name"
                object={aclUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                maxLength={100}
            />
            <FormTextField
                name="description"
                label="Beschreibung"
                object={aclUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                maxLength={255}
            />
            <Stack direction="row" spacing={2} alignItems="center" width={"100%"}>
                <ImageUploadField
                    id="aclUpdate"
                    uploadedFile={logoImage?.data || aclUpdate.logo_url}
                    setUploadedFile={setLogoImage}
                    width={72}
                    height={42}
                    readOnly={!hasPermission}
                />
                <FormTextField
                    name="logo_url"
                    label="Logo"
                    object={aclUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                />
            </Stack>
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                        isValid={formIsValid}
                    />
                </Stack>
            )}
        </>
    );
}