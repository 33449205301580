import * as React from 'react';
import { Checkbox, FormGroup, FormControlLabel, Stack } from '@mui/material';

import FormAddButton from '../../../components/FormAddButton';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormTextField from '../../../components/FormTextField';
import { removeEmptyStrings, useFormSection, validDomain} from '../../../components/misc/useForm';
import ACLSelect from '../../../components/ACLSelectV2';

export default function PlaybackPlayerAddButton({
    createPlayer = () => {},
    hasPermission = false, 
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const playerCreateDefault = {
        name: "",
        acl_id: null,
        domain_whitelist: [],
        is_public: false,
    };

    const { 
        isUpdated, 
        setIsUpdated,
        formUpdate: playerCreate,
        setFormUpdate: setPlayerCreate,
        handleChange,
    } = useFormSection({
        formDefault: playerCreateDefault,
    });

    function handleReset() {
        setPlayerCreate((prev) => {
            return {
                ...prev,
                ...playerCreateDefault,
            };
        });
        setIsUpdated(false);
        handleClose();
    }

    async function handleCreate() {    
        createPlayer({
            player: removeEmptyStrings(playerCreate),
        });
        handleReset();
        handleClose();
    }

    const domainWhitelist = {
        ...playerCreate,
        domain_whitelist: playerCreate.domain_whitelist.join(", "),
    }
    function handleDomainWhitelistChange(e) {
        const { type, name, value } = e.target;
        const domains = value.split(",").map((domain) => domain.trim())
        handleChange({target: {type: type, name: name, value: domains}});
    }
    const domainIsValid = playerCreate.domain_whitelist.some((domain) => validDomain(domain));
    const validForm = (
        playerCreate.name.length > 0 && 
        playerCreate.domain_whitelist.length > 0 && 
        domainIsValid
    );

    return (
        <FormAddButton
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            handleClick={handleClick}
            hasPermission={hasPermission}
        >
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    padding: 4,
                    width: 300,
                }}
            >
                <FormTextField
                    name="name"
                    label="Name"
                    object={playerCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={100}
                />
                <ACLSelect
                    name="acl_id"
                    label="Zugriffskontrollliste (ACL)"
                    placeholder="Keine Einschränkung"
                    object={playerCreate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                />
                <FormTextField
                    name="domain_whitelist"
                    label="Erlaubte Domains"
                    placeholder='beispiel.de, beispiel.org'
                    object={domainWhitelist}
                    handleChange={handleDomainWhitelistChange}
                    hasPermission={hasPermission}
                    error={!domainIsValid}
                    helperText={!domainIsValid ? "Ungültige Domain. " : ""}
                />
                <FormGroup>
                    <FormControlLabel
                        name="is_public"
                        label="Öffentlich"
                        onChange={handleChange}
                        readOnly={!hasPermission}
                        control={
                            <Checkbox
                                checked={playerCreate.is_public ? true : false}
                                color={"blue"}
                            />
                        }
                    />
                </FormGroup>
                {hasPermission && isUpdated && (
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            justifyContent: "flex-end",
                            alignItems: "center",
                            width: "100%",
                            paddingTop: 1,
                        }}
                    >
                        <FormButtonGroup 
                            handleReset={handleReset}
                            handleUpdate={handleCreate}
                            isValid={validForm}
                            spaceBetween
                        />
                    </Stack>
                )}
            </Stack>
        </FormAddButton>
    );
}