import * as React from "react";

import "moment-timezone";
import "moment/locale/de";
import moment from 'moment';

import Chip from '@mui/material/Chip';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import DateSelect from "../../../components/DateSelect";
import ProtocolDetail from "./ProtocolDetail";
import SearchField from "../../../components/SearchField";
import Table from "../../../components/Table";

import { useApp } from "../../../context/App";


export default function AdminProtocol() {
    const { client, backdrop, reload } = useApp();
    const [ready, setReady] = React.useState(false);
    const [protocols, setProtocols] = React.useState([]);
    const [apiQuery, setApiQuery] = React.useState({
        api_method: "",
        api_path: "",
        api_query: "",
        api_payload: "",
        editor_name: "",
        editor_email: "",
        editor_ip: "",
        from_date: moment().subtract(9, "days").format("YYYY-MM-DDTHH:mm"),
        to_date: moment().format("YYYY-MM-DDTHH:mm"),
    });

    const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		const newValue = type === 'checkbox' ? checked : value;
		setApiQuery((prevValues) => ({
			...prevValues,
			[name]: newValue,
		}));
	};

    async function fetchProtocols() {
        try {
            await client.GetCustomerProtocol(apiQuery)
                .then((data) => setProtocols(data));
        } catch (error) {
            console.log(error);
        }
    }

    React.useEffect(() => {
        (async () => {
            await fetchProtocols();
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
    // eslint-disable-next-line
    }, [apiQuery, client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    
    const [rows, setRows] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [searchText, setSearchText] = React.useState("");
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [mousePosition, setMousePosition] = React.useState(null);
    React.useEffect(() => {
        if (selectedRow) {
            setDialogOpen(prevState => true);
        }
    }, [selectedRow]);

    const headCells = [
        {
            id: "created_at",
            numeric: false,
            disablePadding: true,
            label: "Datum",
        },
        {
            id: "editor_name",
            numeric: false,
            disablePadding: true,
            label: "Zugang",
        },
        {
            id: "api_path",
            numeric: false,
            disablePadding: true,
            label: "Aktion",
        },
    ];

    // {
    //     "api_method": "",
    //     "api_path": "",
    //     "api_query": "null",
    //     "api_payload": "",
    //     "editor_name": "",
    //     "editor_email": "",
    //     "editor_ip": "",
    //     "created_at": ""
    // }

    const methodLabel = (method) => {
        switch (method) {
            case "POST": return <Chip label="POST" color="success" size="small" sx={{backgroundColor: "rgba(29,173,34,.8)"}} />;
            case "PUT": return <Chip label="PUT" color="primary" size="small" sx={{backgroundColor: "rgba(0,132,232,.8)"}} />;
            case "DELETE": return <Chip label="DELETE" size="small" sx={{backgroundColor: "rgba(193,39,45,.7)"}} />;
            default: return <Chip label="GET" color="primary" size="small" sx={{textTransform: "initial"}} />;
        }
    }

    function extractPathSegments(path) {
        const basePath = "/api/v1/customer/c61cba4f-68a3-488e-b2e4-ffb8e182794f";
        if (path.startsWith(basePath)) {
            return path.replace(basePath, "").split("/").filter(segment => segment);
        } else {
            return path.split("/").filter(segment => segment && segment !== "api" && segment !== "v1");
        }
    }
    
    function renderPathSegments(path) {
        const segments = extractPathSegments(path);
        return segments.map((segment, index) => (
            <React.Fragment key={index}>
                <span style={{marginLeft: 7, marginRight: 5}}>/</span> {segment}
            </React.Fragment>
        ));
    }

    const pathSegment = (method, path) => {
        // return path.replace("/api/v1/customer/c61cba4f-68a3-488e-b2e4-ffb8e182794f", "");
        return (
            <>
                {methodLabel(method)} {renderPathSegments(path)}
                
            </>
        );
    }

    React.useEffect(() => {
        if (protocols) {
            setRows(protocols.map((p) => {
                return {
                    id: { value: p.created_at, label: "" },
                    created_at: { value: p.created_at, label: moment(p.created_at).format("DD.MM.YYYY HH:mm") },
                    api_method: { value: p.api_method, label: "" },
                    api_path: { value: p.api_path, label: pathSegment(p.api_method, p.api_path) },
                    editor_name: { value: p.editor_name, label: p.editor_name },
                    editor_email: { value: p.editor_email, label: p.editor_email },
                    editor_ip: { value: p.editor_ip, label: p.editor_ip },
                };
            }));
        }
    // eslint-disable-next-line
    }, [protocols]);

    return (
        <Stack
            style={{
                marginBottom: -25,
            }}
            spacing={0}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
            >
                <span style={{marginLeft: 25, maxWidth: 500}}>
                    Das API-Protokoll gibt Auskunft über alle relevanten Änderungen auf der Datenbank.
                </span>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    height={50}
                >
                    <DateSelect
                        label={"Startzeitpunkt"}
                        name="from_date"
                        value={apiQuery.from_date}
                        setValue={handleChange}
                        minDateTime={moment().seconds(0).subtract(2, "years")}
                        color="secondary"
                    />
                    <DateSelect
                        label={"Endzeitpunkt"}
                        name="to_date"
                        value={apiQuery.to_date}
                        setValue={handleChange}
                        minDateTime={moment().seconds(0).subtract(2, "years")}
                        color="secondary"
                    />
                    <SearchField
                        outlined
                        searchText={searchText}
                        setSearchText={setSearchText} 
                    />
                </Stack>
            </Stack>
            <Paper sx={{ width: "100%", marginTop: 5 }}>
                <Table 
                    rows={rows}
                    maxRows={30}
                    sortBy={["created_at"]}
                    sortDir={"asc"}
                    rowHeight={1}
                    dense={false}
                    headCells={headCells}
                    searchText={searchText}
                    setSelectedRow={setSelectedRow}
                    setMousePosition={setMousePosition}
                />
            </Paper>
            {dialogOpen && selectedRow && (
                <ProtocolDetail
                    key={selectedRow ? selectedRow.updated : Date.now()}
                    selectedRow={selectedRow}
                    dialogOpenid={dialogOpen} 
                    setDialogOpen={setDialogOpen}
                    mousePosition={mousePosition}
                />
            )}
        </Stack>
    );
}
