import React from 'react';
import { Checkbox, FormGroup, FormControlLabel, Stack } from '@mui/material';

import ACLSelect from '../../../../components/ACLSelectV2';
import FormButtonGroup from '../../../../components/FormButtonGroup';
import FormTextField from '../../../../components/FormTextField';
import { removeEmptyStrings, useFormSection, validDomain } from '../../../../components/misc/useForm';

export default function PlaybackPlayerSidebarAccessControlForm({ 
    player = {},
    updatePlayer = () => {},
    hasPermission = false, 
}) {
    const playerDefault = {
        acl_id: player.acl_id || null,
        domain_whitelist: player.domain_whitelist || "*",
        token_expires_hours: player.token_expires_hours || "8",
        is_public: player.is_public || false,
    };
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: playerUpdate, 
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: playerDefault,
    });

    async function handleUpdate() {    
        updatePlayer({
            player_id: player.id, 
            player: removeEmptyStrings(playerUpdate),
        });
        setIsUpdated(false);
    }

    const domainWhitelist = {
        ...playerUpdate,
        domain_whitelist: playerUpdate.domain_whitelist.join(", "),
    }
    function handleDomainWhitelistChange(e) {
        const { type, name, value } = e.target;
        const domains = value.split(",").map((domain) => domain.trim())
        handleChange({target: {type: type, name: name, value: domains}});
    }
    const domainIsInvalid = playerUpdate.domain_whitelist.some((domain) => !validDomain(domain));

    return (
        <>
            <ACLSelect
                name="acl_id"
                label="Zugriffskontrollliste (ACL)"
                placeholder="Keine Einschränkung"
                object={playerUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
            />
            <FormTextField
                name="domain_whitelist"
                label="Erlaubte Domains"
                placeholder='beispiel.de, beispiel.org'
                object={domainWhitelist}
                handleChange={handleDomainWhitelistChange}
                hasPermission={hasPermission}
                error={domainIsInvalid}
                helperText={domainIsInvalid ? "Ungültige Domain" : ""}
            />
            <FormTextField
                name="token_expires_hours"
                label="Maximale Wiedergabezeit (Stunden)"
                object={playerUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                maxLength={2}
                type="number"
            />
            <FormGroup>
                <FormControlLabel
                    name="is_public"
                    label="Öffentlich"
                    onChange={handleChange}
                    readOnly={!hasPermission}
                    control={
                        <Checkbox
                            checked={playerUpdate.is_public ? true : false}
                            color={"blue"}
                        />
                    }
                />
            </FormGroup>
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}